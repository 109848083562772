import { isForceMobileAppEnabledForSquare } from '@/services/feature-service';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import { hasMobileAppParam } from '@/common/utils/media';
import { Platform } from 'quasar';

let isAlreadyCheckedInTheCurrentUserSession = false;

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	const allowedPagesOnMobileWebApp = [
		RouteNames.Unsubscribe,
		RouteNames.Reactivate,
		RouteNames.LandingPage,
		RouteNames.IntakeFinished,
		RouteNames.ConfirmEmail,
		RouteNames.IncentiveLandingpageForm,
	];

	if (isAlreadyCheckedInTheCurrentUserSession) {
		// Already checked if we should force the mobile app for this square
		useAuthenticationStore().shouldShowMobileAppPage = false;
		return;
	}

	if (!isSupportedMobileDeviceOS()) {
		// Allow everything if the mobile device OS isn't supported
		// This won't change in the current user session so we'll opt out the next time immediately
		useAuthenticationStore().shouldShowMobileAppPage = false;
		isAlreadyCheckedInTheCurrentUserSession = true;
		return;
	}

	if (
		allowedPagesOnMobileWebApp.includes(to.name as RouteNames) || // We're navigating to a page that's allowed anyway
		useAuthenticationStore().isMobileApp ||
		hasMobileAppParam(to.fullPath)
	) {
		// Allow everything if we're loading a Square web page from within an app
		useAuthenticationStore().shouldShowMobileAppPage = false;
		return;
	}

	const shouldForceMobileApp = await isForceMobileAppEnabledForSquare();
	if (shouldForceMobileApp) {
		useAuthenticationStore().shouldShowMobileAppPage = true;
		return;
	}

	useAuthenticationStore().shouldShowMobileAppPage = false;
	isAlreadyCheckedInTheCurrentUserSession = true;
};

export const isSupportedMobileDeviceOS = () => Platform.is.mobile || Platform.is.ios || Platform.is.android;
