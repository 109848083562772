<script setup lang="ts">
import { computed, onMounted, Ref, ref } from 'vue';
import { getLabelValue } from '@ui/services/label-service';
import { useParticipantStore } from '@/store/participant-store';
import {
	getMigratedSquaresOfParticipant,
	getSquareParticipantAdInformation,
	squareParticipantDetails,
} from '@api/services/query/default/ParticipantService';
import {
	GetAdObjectIdForCurrentUserResponse,
	Role,
	SocialLoginType,
	SquareParticipantDetailItemResponse,
} from '@api/models/query';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import {
	migrateSquareParticipantToCurrentAdAccount,
	updateSquareParticipantDetails,
} from '@api/services/command/default/ParticipantService';
import { useAuthenticationStore } from '@/store/authentication-store';
import { UpdateSquareParticipantDetailRequest } from '@api/models/command';
import { DateTime } from 'luxon';
import { Notify, QDialog } from 'quasar';
import { getSquareSocialLoginConfig } from '@api/services/query/default/SquareService';
import { useRoute } from 'vue-router';
import {
	hideMigrationDialog,
	setHideMigrationCallback,
	setMigrationDialogClosed,
	setMigrationDialogVisible,
} from '@/router/global-guards/migration-guard';
import { useSquareStore } from '@/store/square-store';

const dialog: Ref<QDialog | null> = ref(null);
const authStore = useAuthenticationStore();
const participantStore = useParticipantStore();
const squareStore = useSquareStore();
const squareParticipant: Ref<SquareParticipantDetailItemResponse | undefined> = ref(undefined);
const adInfo: Ref<GetAdObjectIdForCurrentUserResponse | undefined> = ref(undefined);
const migratedSquares: Ref<string[]> = ref([]);
const squareParticipantGuid = ref('');
const canUseExistingAccount = ref(false);
const canUseUpdateAccount = ref(true);
const updateOnHide = ref(true);
const route = useRoute();
const isPersistent = computed(
	() =>
		route.name === RouteNames.ADMigration ||
		participantStore.participant.migrationDismissed ||
		squareStore.info.adMigrationForced,
);
const loading = ref(false);

onMounted(async () => {
	setMigrationDialogVisible();
	setHideMigrationCallback(() => {
		// this is called only by 2 routes which will force it closed,
		// if it is still visible
		setMigrationDialogClosed();
		updateOnHide.value = false;
		dialog.value?.hide();
	});

	try {
		loading.value = true;
		// determine square participant properly
		squareParticipantGuid.value = participantStore.participant?.guid;
		squareParticipant.value = await squareParticipantDetails(squareParticipantGuid.value);
		if (squareParticipant.value.detail?.migrated) {
			// already migrated
			updateOnHide.value = false;
			dialog.value?.hide();
			authStore.setAdObjectId(
				squareParticipant.value.adStatus?.adObjectId || squareParticipant.value.detail.adObjectId || null,
			);
			return;
		}
		if (squareParticipant.value.adStatus?.migratedOnOtherSquares) {
			migratedSquares.value = await getMigratedSquaresOfParticipant(squareParticipantGuid.value);
			// see if this role can use the AD account already present on other squares
			//        - if this role is participant, we do not care about existing account
			//        - if this role is not participant, we need to allow only non socials
			canUseExistingAccount.value =
				squareParticipant.value.detail?.role === Role.Participant ||
				squareParticipant.value.adStatus?.socialLoginType === SocialLoginType.None;
			if (canUseExistingAccount.value && squareParticipant.value.adStatus?.socialLoginType !== SocialLoginType.None) {
				// if the initial AD was a social, we need to know if it is allowed on this square or not
				const socialLoginConfig = await getSquareSocialLoginConfig();
				canUseExistingAccount.value =
					socialLoginConfig.types?.find(
						(t) => t.type === squareParticipant.value?.adStatus?.socialLoginType && t.enabled,
					) !== undefined;
			}

			// for non participants already migrated, its not possible to update account
			if (canUseExistingAccount.value && squareParticipant.value.detail?.role !== Role.Participant) {
				canUseUpdateAccount.value = false;
			}
		} else {
			adInfo.value = await getSquareParticipantAdInformation();
		}
	} finally {
		loading.value = false;
	}
});

const closeDialog = async () => {
	setMigrationDialogClosed();
	if (!updateOnHide.value) {
		dialog.value?.hide();
		return;
	}
	// update dialog timestamp
	const updateRequest: UpdateSquareParticipantDetailRequest = {
		squareParticipantGuid: participantStore.participant.guid,
		username: participantStore.participant.username,
		userType: participantStore.participant.role,
		email: participantStore.participant.email,
		firstName: participantStore.participant.firstName,
		lastName: participantStore.participant.lastName,
		communicationEmail: participantStore.participant.communicationEmail,
		dateMigrationModalShown: DateTime.utc(),
		isBasicInfoChanged: false,
	};
	await updateSquareParticipantDetails(updateRequest);
	participantStore.setDateMigrationDialogShown(DateTime.utc());
	participantStore.setMigrationDismissed(true);

	// clear the dialog visibility
	setHideMigrationCallback(() => {});

	dialog.value?.hide();
};

const updateAccount = async () => {
	// we update time and just navigate, the AD update account route will close the dialog for us
	if (!participantStore.participant.migrationDismissed) {
		await closeDialog();
	} else {
		hideMigrationDialog();
	}
	// for non participants, go to email migration
	const goToRoute =
		participantStore.participant.role !== Role.Participant
			? { name: RouteNames.ADEmailMigration }
			: { name: RouteNames.ADUpdateAccount };
	await router.push(goToRoute);
};

const useExistingAccount = async () => {
	// we need to transfer everything about this participant to the AD user on other squares
	const migrationResult = await migrateSquareParticipantToCurrentAdAccount();
	if (migrationResult.adObjectId) {
		// success!
		// we need to fake AD user in authorization store,
		// so that it behaves like an AD user without actually logging in AD
		authStore.setAdObjectId(migrationResult.adObjectId);
		// we update time and just navigate, the AD activation route will close the dialog for us
		if (!participantStore.participant.migrationDismissed) {
			updateOnHide.value = false;
			await closeDialog();
		} else {
			hideMigrationDialog();
		}
		await router.push({
			name: RouteNames.ADActivation,
			query: {
				identity: migrationResult.guid,
			},
		});
	} else {
		Notify.create({
			caption: migrationResult.error,
			message: migrationResult.errorDescription,
			type: 'negative',
			timeout: 30000,
			actions: [{ icon: 'fa fa-times-circle', color: 'white', round: true }],
		});
	}
};

const signout = async () => {
	participantStore.setMigrationDismissed(true);
	await authStore.signout(false, false, false);
	setMigrationDialogClosed();
};
</script>

<template>
	<q-dialog
		id="ad_migration_dialog"
		ref="dialog"
		class="ad-migration-page"
		:persistent="isPersistent"
		@hide="closeDialog()"
	>
		<div
			class="ad-migration-page__form q-mb-md"
			name="ad-migration-page-form"
		>
			<div>
				<div class="q-mt-md">
					{{
						getLabelValue('LabelHi', `Hi ${participantStore.participant.firstName},`, {
							firstname: participantStore.participant.firstName || '',
						})
					}}
				</div>

				<div class="ad-migration-page__intro-text q-mb-md">
					{{
						getLabelValue(
							'LabelADMigrationHeader',
							`We\'re in the process of enhancing our login system for a better and more secure experience,
            which requires an update of your account on this community.`,
						)
					}}
				</div>

				<div
					v-if="squareParticipant?.adStatus?.migratedOnOtherSquares"
					class="ad-migration-page__body-text"
				>
					{{
						getLabelValue(
							'LabelADMigrationExistingAccount',
							'We have noticed that you have already updated your account on:',
						)
					}}
					<ul>
						<li
							v-for="squareName in migratedSquares"
							:key="squareName"
						>
							{{ squareName }}
						</li>
					</ul>
					<br />
				</div>

				<div
					v-if="canUseExistingAccount && canUseUpdateAccount"
					class="ad-migration-page__question-text q-mb-md"
				>
					{{ getLabelValue('LabelADMigrationQuestion', 'How would you like to continue?') }}
				</div>

				<div v-if="canUseExistingAccount && squareParticipant?.adStatus?.migratedOnOtherSquares">
					<q-btn
						:loading="loading"
						class="ad-migration-page__existing-login-button full-width q-mb-sm q-mt-sm text-bold bg-primary text-white"
						no-caps
						@click="useExistingAccount"
					>
						{{ getLabelValue('LabelADMigrationExistingAccountButton', 'Continue with existing login details') }}
					</q-btn>
					<div class="ad-migration-page__button-text q-mb-lg">
						{{
							getLabelValue(
								'LabelADMigrationExistingAccountDescription',
								`By selecting this option, you can sign in with existing login details from the community/communities above,
              which will become your new login details for this community as well.`,
							)
						}}
					</div>
				</div>

				<q-btn
					v-if="canUseUpdateAccount"
					:loading="loading"
					class="ad-migration-page__update-account-button full-width q-mb-sm q-mt-sm text-bold bg-primary text-white"
					no-caps
					@click="updateAccount"
				>
					{{ getLabelValue('LabelADMigrationUpdateAccountButton', 'Update my account') }}
				</q-btn>
				<div
					v-if="canUseUpdateAccount"
					class="ad-migration-page__button-text"
				>
					{{
						getLabelValue(
							'LabelADMigrationUpdateAccountDescription',
							`By selecting this option, you will go through brief steps to update your account on this community with new login details.`,
						)
					}}
				</div>

				<div class="q-mt-lg text-right">
					<label
						class="ad-migration-page__signout"
						@click="signout"
					>
						{{ getLabelValue('LabelProfileLogout', 'Logout') }}
					</label>
				</div>
			</div>
		</div>
	</q-dialog>
</template>

<style lang="scss">
.ad-migration-page {
	box-sizing: border-box;
	object-fit: cover;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	background-color: $azure-background-color;

	&__form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $light;
		border-radius: 5px;
		padding: 20px 40px;
	}

	&__form-email {
		font-weight: normal;
	}

	&__update-account-button,
	&__existing-login-button {
		color: $light;
	}

	&__form-button {
		transition: none;
		border-radius: 4px;
		text-transform: none;
		height: 48px;

		.q-focus-helper {
			display: none;
		}

		&:before {
			box-shadow: none;
		}
	}

	&__intro-text,
	&__body-text {
		text-align: left;
	}

	&__signout {
		cursor: pointer;
	}

	&__button-text {
		text-align: center;
		font-size: smaller;
	}

	&__question-text {
		text-align: left;
		font-weight: bold;
	}
}
</style>
