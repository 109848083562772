import * as angular from 'angular';
import customStateParams from './vue-angular-helpers/customStateParams';
import CustomUiRouter from './vue-angular-helpers/customUiRouter';
import '@/angular/app';
import ssoCheckService from './dataservices/sso-check.service';
import pageService from './dataservices/page.service';
import featureService from './dataservices/feature.service';
import ssoService from './dataservices/sso.service';
import imagesFactory from './factories/images-factory';
import squareFactory from './factories/square-factory';
import { useAuthenticationStore } from '@/store/authentication-store';
import { customStylesConfig } from './vue-angular-helpers/custom-styles';
import { trackingService } from './dataservices/tracking.service';
import CustomLocation from './vue-angular-helpers/customLocation';
import tourService from './dataservices/tour.service';
import { initGeeTest } from 'lib/gt';
// eslint-disable-next-line no-restricted-imports, @typescript-eslint/naming-convention
import * as _ from 'lodash-es';
import { spinnerService } from './dataservices/spinner.service';
import { overridePromises } from './override-promises';

export const createInsitesApp = async () => {
	// Has to be imported dynamically because we want Pinia to be available when initializing the proxy
	const authService = (await import('./dataservices/auth.service')).default;
	const userService = (await import('./dataservices/user.service')).default;
	const participantFactory = (await import('./factories/participant-factory')).default;
	const notificationService = (await import('./dataservices/notification.service')).notificationService;
	const userNotificationService = (await import('./dataservices/usernotification.service')).userNotificationService;

	angular
		.module('insitesApp')
		.config(customStylesConfig)
		.factory('$state', () => new CustomUiRouter())
		.factory('$stateParams', () => customStateParams)
		.factory('$location', () => new CustomLocation())
		.factory('$transitions', () => ({
			onBefore: _.noop,
			onStart: _.noop,
			onSuccess: _.noop,
		}))
		.factory('ssocheckService', () => ssoCheckService)
		.factory('iscPages', () => pageService)
		.factory('authService', () => authService)
		.factory('featureService', () => featureService)
		.factory('ssoService', () => ssoService)
		.factory('userService', () => userService)
		.factory('imagesFactory', () => imagesFactory)
		.factory('participantFactory', () => participantFactory)
		.factory('squareFactory', () => squareFactory)
		.factory('notifications', () => notificationService)
		.factory('userNotificationService', () => userNotificationService)
		.factory('trackingService', () => trackingService)
		.factory('tourService', () => tourService)
		.factory('mobiletourService', () => tourService)
		.factory('spinnerservice', () => spinnerService)
		.factory('initGeeTest', () => initGeeTest)
		.constant('_', _)
		// We have to make sure the angular http service has the right token.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.run(['httpService', (httpService: any) => httpService.useToken(useAuthenticationStore().token)])
		.run(overridePromises);
};
