import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';

const HelpCenter = () => import('@/pages/help-center-page.vue');

export const helpcenterRoute: RouteRecordRaw = {
	name: RouteNames.Helpcenter,
	path: '/helpcenter',
	component: HelpCenter,
	meta: {
		requireLogin: true,
		title: '(LabelWindowTitleHelpcenter)',
	},
	children: [
		{
			name: RouteNames.HelpcenterContact,
			path: `contact/:helpcenterPage(${guidRegex})`,
			component: () => import('@/pages/contact-page.vue'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleContact)',
				mobileTitle: '(LabelHelpCenter)',
			},
		},
		{
			name: RouteNames.HelpcenterCustom,
			path: `custom/:pageGuid(${guidRegex})`,
			component: () => import('@/pages/custom-page.vue'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleCustom)',
				mobileTitle: '(LabelHelpCenter)',
			},
		},
		{
			name: RouteNames.HelpcenterFaq,
			path: `faq/:helpcenterPage(${guidRegex})`,
			component: () => import('@/pages/faq-page.vue'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleFAQ)',
				mobileTitle: '(LabelHelpCenter)',
			},
		},
		{
			name: RouteNames.HelpcenterPlatformTour,
			path: `platformtour/:helpcenterPage(${guidRegex})`,
			component: () => import('@/pages/platform-tour.vue'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleTour)',
				mobileTitle: '(LabelHelpCenter)',
			},
		},
	],
};
