<template>
	<div class="q-gutter-sm isc-checkbox-selection">
		<div>
			<q-checkbox
				:model-value="selectionOption"
				:label="label"
				:left-label="props.leftLabel"
				color="secondary"
				@update:model-value="emit('selection-change', $event)"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
export interface Props {
	selectionOption: boolean | null;
	label: string;
	leftLabel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	leftLabel: false,
});

const emit = defineEmits<(e: 'selection-change', payload: boolean | null) => void>();
</script>
