import { BUILD_VERSION } from '@/common/env.config';
import { getCacheValue, removeCacheValue, setCache } from '@ui/common/utils/cache';
import { reduce, replace, template, templateSettings } from 'lodash-es';
import { defineStore } from 'pinia';
import { useAuthenticationStore } from './authentication-store';
import { getFileConfiguration } from '@api/services/query/default/ConfigurationService';
import { FileConfigurationDto } from '@api/models/query';
import { FileType } from '@/common/constants/enums';
import { FileSize } from './constants-store.enums';
import { listLabel } from '@api/services/query/default/ProjectService';

const labelsCacheKey = `Labels-${BUILD_VERSION}`;
const labelsCacheDuration = 1;
const languageCacheKey = 'Language';

export const useConstantsStore = defineStore('constants', {
	state: () => ({
		labels: {} as Record<string, string>,
		language: '',
		fileConfiguration: [] as FileConfigurationDto[],
	}),

	actions: {
		async init() {
			this.language = getCacheValue<string>(languageCacheKey) || '';
			this.labels = getCacheValue<Record<string, string>>(labelsCacheKey) || {};

			const authStore = useAuthenticationStore();

			authStore.loginFunctions.push(async () => {
				this.fileConfiguration = await getFileConfiguration();
			});

			authStore.logoutFunctions.push(() => {
				removeCacheValue(labelsCacheKey);
			});
		},
		async fetchLabels() {
			const labelList = (await listLabel(this.language)).list || [];
			for (const label of labelList) {
				if (label.name && label.value) {
					this.labels[label.name] = label.value;
				}
			}
			setCache({
				key: labelsCacheKey,
				value: this.labels,
				duration: labelsCacheDuration,
			});
		},
		setLanguage(language: string) {
			this.language = language;
			setCache({ key: languageCacheKey, value: language });
		},
	},

	getters: {
		getLabelValue: (state) => (labelName: string, replacements?: Record<string, string>) => {
			let label = state.labels[labelName];
			if (replacements === undefined) {
				return label;
			}
			templateSettings.interpolate = /{([\s\S]+?)}/g;
			const compiled = template(label); // Compile the label string into a template function
			label = compiled(replacements); // Call the template function with the replacements object
			return label;
		},
		getLabelValueReplaceUrl:
			(state) =>
			(labelName: string, url: string, attributes: Record<string, string> | null = null) => {
				const matchTextUrlPlaceholder = /{(([^}]*)\|)?(url)}/g;
				const labelValue = state.labels[labelName];

				const attributeString = reduce(
					attributes,
					(result, value, key) => {
						result += ` ${key}="${value}"`;
						return result;
					},
					'',
				);
				return replace(
					labelValue,
					matchTextUrlPlaceholder,
					(_match, _match2, text) => `<a href="${url}"${attributeString}>${text ?? url}</a>`,
				);
			},
		isLabelsSet: (state) => Object.keys(state.labels).length !== 0,
		getAcceptedMimeTypes: (state) => (fileType: FileType) => {
			let mimeTypes: string[] = [];
			const fileTypeConfig = state.fileConfiguration.find((config) => config.fileType === fileType);

			if (fileTypeConfig && fileTypeConfig.extensions) {
				for (const extension of fileTypeConfig.extensions) {
					if (extension.mimeType) {
						mimeTypes = mimeTypes.concat(extension.mimeType);
					}
				}
			}
			return mimeTypes;
		},
		getMaxFileSize: (state) => (fileType: FileType, fileSize: FileSize) => {
			const file = state.fileConfiguration.find((config) => config.fileType === fileType);
			if (file && file.maxFileSizeMb) {
				return fileSize === FileSize.MB ? file.maxFileSizeMb : file.maxFileSizeMb * Math.pow(1024, 2);
			}
		},
	},
});
