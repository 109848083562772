import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { guidRegex } from '@/router/router.constants';
import { h } from 'vue';
import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { usePagesStore } from '@/store/pages-store';

// Temporary route for testing
export const forumRoute: RouteRecordRaw = {
	name: RouteNames.Forum,
	path: '/forum/:pageGuid/room/:roomGuid?',
	alias: ['/forum/:pageGuid'],
	component: () => import('@/pages/lounge-page.vue'),
	meta: {
		requireLogin: true,
		title: (routeParams: RouteParams) => {
			const pagesStore = usePagesStore();
			return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleForum)';
		},
	},
	children: [
		{
			name: RouteNames.ForumConversation,
			path: `conversation/:conversationGuid(${guidRegex})`,
			component: getWrappedAngularComponent('forumConversationComponent'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleConversation)',
			},
		},
		{
			name: RouteNames.ForumNew,
			path: 'new',
			component: getWrappedAngularComponent('forumNewConversation'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleNewConversation)',
			},
		},
		{
			name: RouteNames.ForumRoom,
			path: '',
			component: h(() => ''),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleForumRoom)',
			},
		},
		{
			name: RouteNames.DiscussionTopic,
			path: `conversation/:conversationGuid(${guidRegex})`,
			component: getWrappedAngularComponent('discussionTopicComponent'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleConversation)',
			},
		},
		{
			name: RouteNames.DiscussionTopicNew,
			path: 'new',
			component: getWrappedAngularComponent('newDiscussionTopicComponent'),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleNewConversation)',
			},
		},
	],
};
