import { Area } from '@/common/constants/enums';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useConstantsStore } from '@/store/constants-store';
import { usePagesStore } from '@/store/pages-store';
import { computed, ComputedRef } from 'vue';
import { RouteParams, useRoute } from 'vue-router';

export interface MobileNavigationInfo {
	isHomePageNavigation: ComputedRef<boolean>;
	shouldGoToMorePage: ComputedRef<boolean>;
	mobileNavigationPageTitle: ComputedRef<string>;
}

export const useMobileNavigationInfo = (): MobileNavigationInfo => {
	const route = useRoute();
	const pagesStore = usePagesStore();

	const getRouteTitle = (title: string | ((params: RouteParams) => string) | undefined, params: RouteParams) => {
		if (title) {
			return `Square: ${typeof title === 'function' ? title(params) : title}`;
		}
		return undefined;
	};

	const currentPageDetail = computed(() =>
		pagesStore.pages.find(
			(page) =>
				page.route &&
				route.name &&
				route.name.toString().includes(page.route) &&
				route.params?.pageGuid === page.routeParams?.pageGuid,
		),
	);

	const isHomePageNavigation = computed(
		() =>
			!!(currentPageDetail.value && currentPageDetail.value.area === Area.MainPages) || route.name === RouteNames.More,
	);
	const shouldGoToMorePage = computed(() => !isHomePageNavigation.value);
	const mobileNavigationPageTitle = computed(() => {
		const pageTitle =
			currentPageDetail.value?.name || getRouteTitle(route.meta.mobileTitle || route.meta.title, route.params) || '';

		// Check if it's a label that should be translated
		const labelPattern = /\((.*)\)/;
		const labelMatches = pageTitle.match(labelPattern);
		const label = labelMatches && labelMatches.length >= 2 ? labelMatches[1] : pageTitle;

		const labelValue = useConstantsStore().getLabelValue(label, route.meta?.replacements);
		return labelValue || pageTitle;
	});

	return {
		isHomePageNavigation,
		shouldGoToMorePage,
		mobileNavigationPageTitle,
	};
};
