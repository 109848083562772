// Import constants Store to fetch labels
import { useConstantsStore } from '@/store/constants-store';

const labels = () => {
	const constantsStore = useConstantsStore();
	return constantsStore.labels;
};

export default {
	labels,
};
