import { PageType } from '@/common/constants/enums';
import { usePriorityNavigationHelper } from '@/components/navigation-menu/composables/use-priority-navigation-helper';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useConstantsStore } from '@/store/constants-store';
import { usePagesStore } from '@/store/pages-store';
import { useSquareStore } from '@/store/square-store';
import Shepherd from 'shepherd.js';
import { ref, getCurrentInstance, nextTick } from 'vue';
import { Dialog, Screen, Quasar, DialogChainObject } from 'quasar';
import { useNotificationsStore } from '@/store/notifications-store';
import { platformTourStarted } from '@api/services/command/default/ParticipantService';

const isActive = ref(false);
let tour: Shepherd.Tour;
let mobileTour: DialogChainObject | null = null;

export const useTour = () => {
	const createTour = () => {
		const { getLabelValue } = useConstantsStore();
		const pagesStore = usePagesStore();
		const notificationsStore = useNotificationsStore();

		tour = new Shepherd.Tour({
			useModalOverlay: true,
			exitOnEsc: true,
			defaultStepOptions: {
				cancelIcon: {
					enabled: true,
					label: getLabelValue('LabelAriaCancel'),
				},
				canClickTarget: false,
				when: {
					show() {
						createAndAddProgressIndicator(this);
						focusOnTour(this);
					},
				},
				modalOverlayOpeningRadius: 4,
			},
		});

		// Welcome step
		tour.addStep(
			createStep(
				getLabelValue('LabelPlatformTourIntroTitle'),
				getLabelValue('LabelPlatformTourIntro'),
				'welcome',
				false,
				true,
				[],
				0,
				true,
				false,
			),
		);

		// Page steps
		const pagesSteps: Shepherd.Step.StepOptions[] = pagesStore.mainPages
			.filter((p) => p.pageType !== PageType.MainPageCustom)
			.map((p) => {
				let title = p.name;
				let text = p.intro;
				// The home page has a hardcoded title and intro
				if (p.pageType === PageType.Home) {
					title = getLabelValue('LabelHomeName');
					text = getLabelValue('LabelHomeIntro');
				}

				return createStep(title, text, p.id);
			});
		tour.addSteps(pagesSteps);

		// Incentive page
		if (pagesStore.incentivePages.some((p) => p.pageType === PageType.MyIncentive)) {
			tour.addStep(
				createStep(
					getLabelValue('LabelIncentives'),
					getLabelValue('LabelIncentivesIntro'),
					'incentives',
					true,
					true,
					[],
					8,
				),
			);
		}

		// Notifications step
		tour.addStep(
			createStep(
				getLabelValue('LabelNotifications'),
				getLabelValue('LabelNotificationsIntro'),
				'notifications',
				true,
				true,
				[],
				8,
			),
		);

		// Helpcenter step
		tour.addStep(
			createStep(
				getLabelValue('LabelHelpCenter'),
				getLabelValue('LabelHelpCenterIntro'),
				'helpcenter',
				true,
				true,
				[],
				8,
			),
		);

		// Profile page
		// Only add profile page if sso is not enabled
		const shouldAddProfilePage = !useSquareStore().info.ssoEnabled;
		if (shouldAddProfilePage) {
			const actionLabel =
				notificationsStore.profileActivities.length > 0
					? 'LabelPlatformTourDialogOkButton'
					: 'LabelPlatformTourDialogOkButtonNoSurvey';
			tour.addStep(
				createStep(
					getLabelValue('LabelMyProfile'),
					getLabelValue('LabelMyProfileIntro'),
					'profile',
					true,
					false,
					[
						{
							action: tour.next,
							text: getLabelValue(actionLabel),
							classes: 'bg-primary text-white q-px-md',
						},
					],
					8,
					false,
				),
			);
			// The reason that the 'complete' event of the tour is used instead of adding this to the action of the button,
			// is to make sure that the page navigation also happens when the tour is being navigated with arrow functions.
			tour.on('complete', (event: { index: number; tour: Shepherd.Tour }) => {
				if (event.tour.steps[event.index].options.attachTo?.element === '[data-v-step="profile"]') {
					router.push({ name: RouteNames.Profile });
				}
			});
		}

		// Add event listeners
		// When the tour becomes active, set the isActive value and make sure we can't scroll the body.
		tour.on('active', () => {
			isActive.value = true;
			document.documentElement.style.setProperty('overflow', 'hidden', 'important');
			document.documentElement.style.setProperty('height', '100%', 'important');
			closeOnBackdropClick();
		});
		// When the tour becomes inactive, set the isActive value back to false and clear the body scroll locks added in the on('active') method
		tour.on('inactive', () => {
			isActive.value = false;
			document.documentElement.style.removeProperty('overflow');
			document.documentElement.style.removeProperty('height');
			document.documentElement.scrollTop = 0;
		});
	};

	/**
	 * Create a step for the tour
	 * @param title Title to be shown in the card
	 * @param text Text to be shown in the body of the card
	 * @param element Value of the data-v-step attribute on which the step should be shown
	 * @param addBackButton Should the back button be added
	 * @param addNextButton Should the next button be added
	 * @param additionalButtons Additional buttons that can be added after the next button.
	 * @param padding Amount of padding to add around the modal overlay opening
	 */
	const createStep = (
		title: string | undefined,
		text: string | undefined,
		element: string | number,
		addBackButton = true,
		addNextButton = true,
		additionalButtons: Shepherd.Step.StepOptionsButton[] = [],
		padding?: number,
		cancelIcon?: boolean,
		arrow?: boolean,
	): Shepherd.Step.StepOptions => {
		const { getLabelValue } = useConstantsStore();
		const buttons: Shepherd.Step.StepOptionsButton[] = [];

		if (addBackButton) {
			// Fix for a quasar bug. Normally we should use Quasar.lang, but that returns undefined.
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const icon = Quasar.lang.props.rtl ? 'fas fa-arrow-right fa-lg' : 'fas fa-arrow-left fa-lg';
			buttons.push({
				action: tour.back,
				text: `<i class="${icon} q-mr-sm"></i><span>${getLabelValue('LabelPlatformTourPrevious')}</span>`,
				classes: 'shepherd-back-button q-btn q-btn--actionable q-btn--flat',
			});
		}

		if (addNextButton) {
			// Fix for a quasar bug. Normally we should use Quasar.lang, but that returns undefined.
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const icon = Quasar.lang.props.rtl ? 'fas fa-arrow-left fa-lg' : 'fas fa-arrow-right fa-lg';
			buttons.push({
				action: tour.next,
				text: `<span>${getLabelValue('LabelPlatformTourNext')}</span><i class="${icon} q-ml-sm"></i>`,
				classes: 'shepherd-next-button q-btn q-btn--actionable q-btn--flat',
			});
		}

		if (additionalButtons.length) {
			buttons.push(...additionalButtons);
		}

		// since we don't want our layout to break. We hide the cancel icon instead of removing it.
		const classes = (cancelIcon ?? true) ? '' : 'hide-cancel-icon';

		return {
			title,
			text,
			attachTo: {
				element: `[data-v-step="${element}"]`,
				on: 'bottom',
			},
			buttons,
			modalOverlayOpeningPadding: padding,
			classes,
			arrow: arrow ?? true,
			popperOptions: {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 10 + (padding ?? 0)],
						},
					},
				],
			},
			beforeShowPromise: async () => {
				const { listPageOverflow } = usePriorityNavigationHelper();
				const moreButton = document.querySelector('.navigation-bar-tabs__more-button-desktop') as HTMLButtonElement;
				if (!moreButton || listPageOverflow.value.length === 0) {
					return;
				}
				const moreMenu = document.querySelector('.navigation-bar-tabs__more-button-desktop-menu');
				// Programmatically click the menu.
				// There are 2 reasons to click the menu:
				//   1. The more menu isn't visible yet and the page is in there -> click to open
				//   2. The more menu is visible but the page isn't in there -> click to close
				if (
					(!moreMenu && listPageOverflow.value.some((p) => p.id === element)) ||
					(moreMenu && !listPageOverflow.value.some((p) => p.id === element))
				) {
					moreButton.click();
					await nextTick();
					// Tour overlay is not positioned correctly on more menu items unless we force a re-render
					getCurrentInstance()?.proxy?.$forceUpdate();
				}
			},
		};
	};

	const focusOnTour = (step: Shepherd.Step) => {
		const currentStepElement = step.getElement();
		if (!currentStepElement) {
			return;
		}
		currentStepElement.focus();
		currentStepElement.onblur = (ev: FocusEvent) => {
			if (!ev.relatedTarget) {
				currentStepElement.focus();
			}
		};
	};

	const closeOnBackdropClick = () => {
		const backdrop = document.getElementsByClassName('shepherd-modal-overlay-container')[0];
		backdrop.addEventListener('click', () => tour.cancel());
	};

	/**
	 * Will create and add a progress indicator to the footer of the step.
	 * Will be placed under the button.
	 * @param step The current step
	 */
	const createAndAddProgressIndicator = (step: Shepherd.Step) => {
		const currentStepElement = step.getElement();
		if (!currentStepElement) {
			return;
		}

		const footer = currentStepElement.querySelector('.shepherd-footer');
		if (!footer) {
			return;
		}

		const allSteps = step.getTour().steps;
		const currentStepIndex = allSteps.indexOf(step);

		// The progress indicator is a div with an unordered list in it.
		// Each list item has an anchor with a onclick that will go to the corresponding step.
		// The current step will be marked by a filled circle.
		// The other steps will be marked by an empty circle.
		// <div class="shepherd-progress">
		//   <ul>
		//     <li><a><i class="far fa-circle"></i></a></li>
		//     <li><a><i class="fas fa-circle"></i></a></li> <-- active step
		//     <li><a><i class="far fa-circle"></i></a></li>
		//   </ul>
		// </div>
		const progress = document.createElement('div');
		progress.className = 'shepherd-progress';
		const list = document.createElement('ul');
		allSteps.forEach((_, i) => {
			const listItem = document.createElement('li');
			const listItemAnchor = document.createElement('a');
			listItemAnchor.onclick = () => {
				step.getTour().show(i);
			};

			// Filled in circle for current step.
			// Empty circle for the other steps.
			const iconType = currentStepIndex === i ? 'fas' : 'far';
			listItemAnchor.innerHTML = `<i class="${iconType} fa-circle"></i>`;

			listItem.insertAdjacentElement('afterbegin', listItemAnchor);
			list.insertAdjacentElement('beforeend', listItem);
		});
		progress.insertAdjacentElement('afterbegin', list);
		footer.insertAdjacentElement('beforeend', progress);
	};

	/**
	 * Starts the tour unless the platform tour page has been disabled.
	 * Will create the tour if it hasn't been created before or if reset is true
	 */
	const startTour = (reset?: boolean) => {
		// Don't start the tour if the tour page is disabled
		const tourPage = usePagesStore().helpCenterPages.find((p) => p.pageType === PageType.PlatformTour);
		if (!tourPage) {
			return;
		}

		// Before creating a tour reset the previous tour (desktop & mobile) if any
		if (tour) {
			tour.cancel();
		}

		if (mobileTour) {
			mobileTour.hide();
			mobileTour = null;
		}

		if (Screen.lt.md) {
			platformTourStarted();
			startMobileTour();
			return;
		}

		if (!tour || reset) {
			createTour();
		}
		platformTourStarted();

		return tour.start();
	};

	const startMobileTour = () => {
		const constantsStore = useConstantsStore();
		const notificationsStore = useNotificationsStore();
		const actionLabel =
			notificationsStore.profileActivities.length > 0 ? 'LabelMobileTourButton' : 'LabelMobileTourButtonNoSurvey';
		mobileTour = Dialog.create({
			title: constantsStore.getLabelValue('LabelMobileTourTitle'),
			message: constantsStore.getLabelValue('LabelMobileTourBody'),
			class: 'platform-tour-mobile',
			ok: {
				label: constantsStore.getLabelValue(actionLabel),
				noCaps: true,
				color: 'primary',
				class: 'q-mb-sm q-mr-sm text-bold',
			},
		})
			.onOk(() => {
				mobileTour = null;
				router.push({ name: RouteNames.Profile });
			})
			.onDismiss(() => (mobileTour = null));
	};

	return { startTour, isActive };
};
