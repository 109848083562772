import { LocationQuery, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import { PageType } from '@/common/constants/enums';

const homePage = () => import('@/pages/home/home-page.vue');

export const homeRoute: RouteRecordRaw = {
	name: RouteNames.Home,
	path: '/',

	component: homePage,
	meta: {
		title: () => {
			const homePages = usePagesStore().pageByType(PageType.Home);
			return homePages && homePages.length > 0
				? homePages[0].name || '(LabelWindowTitleHome)'
				: '(LabelWindowTitleHome)';
		},
		requireLogin: true,
	},
	beforeEnter: (to) => {
		const queryParams = to.query;
		const queryParamsDefaults: LocationQuery = {
			startTour: 'false',
		};
		to.query = {
			...queryParamsDefaults,
			...queryParams,
		};
	},
};
