<template>
	<q-layout
		v-if="isInitialized"
		view="hHh LpR fFf"
	>
		<navigation-menu v-if="shouldShowNavigationMenu && !shouldShowMobileAppPage" />
		<app-banner v-if="shouldShowAppBanner && !isWebView" />
		<q-page-container :style="{ 'background-image': `url(${backgroundImage})` }">
			<q-page class="column no-wrap">
				<div
					:class="{ column: nonMainRoute, 'center-page': shouldCenterPage }"
					style="flex-grow: 1"
				>
					<router-view
						v-if="!shouldShowMobileAppPage"
						:class="{ 'centered-page': shouldCenterPage }"
					/>
				</div>
				<desktop-footer v-if="shouldShowNavigationMenu && !shouldShowMobileAppPage" />
				<q-footer
					v-if="!shouldShowNavigationMenu && !shouldShowMobileAppPage && !hideFooter"
					class="non-authorized-footer bg-white shadow-10 text-grey row justify-center q-pt-lg q-pb-lg"
				>
					<footer-logo class="non-authorized-footer__logo" />
				</q-footer>
			</q-page>
		</q-page-container>
		<component
			:is="forceMobileAppComponent"
			v-if="shouldShowMobileAppPage"
		/>
	</q-layout>
</template>

<script lang="ts" setup>
import AppBanner from '@/components/banners/app-banner.vue';
import NavigationMenu from '@/components/navigation-menu/navigation-menu.vue';
import DesktopFooter from '@/components/desktop-footer/desktop-footer.vue';
import FooterLogo from '@/components/navigation-menu/footer-logo.vue';
import { useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, watch } from 'vue';
import { clearExpiredItems } from '@ui/common/utils/cache';
import { loadVideoScripts } from '@/common/utils/media';
import { useAuthenticationStore } from './store/authentication-store';
import { emptyImage, useImagesStore } from './store/images-store';
import { addFathom, addCookieYes } from '@/common/utils/startup';
import { RouteNames } from '@/router/routes/route-names.enum';
import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { useParticipantStore } from './store/participant-store';
import { isInitialized } from '@/common/utils/startup';
import { setCurrentUser } from '@ui/composables/use-current-user';
import { useQuasar } from 'quasar';
import { useSquareStore } from '@/store/square-store';

// Clear expired items from local storage
clearExpiredItems();
const route = useRoute();
const router = useRouter();
const isMainRoute = computed(() => route.name?.toString().startsWith(RouteNames.Main));
const shouldShowNavigationMenu = computed(
	() =>
		useAuthenticationStore().isAuthorized &&
		!useAuthenticationStore().isMobileApp &&
		(isMainRoute.value ||
			route.name?.toString() === RouteNames.NotFound ||
			route.name?.toString() === RouteNames.SplashPage),
);

const isWebView = computed(() => window.flutter_inappwebview);
const hideFooter = computed(() => !!route.meta?.hideFooter);

const shouldShowMobileAppPage = computed(() => useAuthenticationStore().shouldShowMobileAppPage);
const forceMobileAppComponent = getWrappedAngularComponent('forceMobileAppComponent');

const $q = useQuasar();
const isSmallScreen = computed(() => $q.screen.lt.md);
const squareStore = useSquareStore();

const shouldShowAppBanner = computed(
	() =>
		squareStore.info.shouldShowAppStoreLinksOnMobileWeb &&
		useAuthenticationStore().isAuthorized &&
		isSmallScreen.value &&
		(isMainRoute.value ||
			route.name?.toString() === RouteNames.NotFound ||
			route.name?.toString() === RouteNames.SplashPage),
);

const imagesStore = useImagesStore();
const backgroundImage = computed(() => {
	if (isAdPage.value) {
		return imagesStore.backgroundLanding && imagesStore.backgroundLanding !== emptyImage
			? imagesStore.backgroundLanding
			: imagesStore.background;
	}
	return imagesStore.background;
});

// Adding class=column in template causes issues with the page overlapping the banner image so limiting this to non-main pages
const nonMainRoute = computed(() => route.matched[0]?.name === RouteNames.NonMain);
const shouldCenterPage = computed(() => {
	const pagesToCenter = [
		RouteNames.ChangePassword,
		RouteNames.ConfirmEmail,
		RouteNames.Login,
		RouteNames.Logout,
		RouteNames.Reactivate,
		RouteNames.Registration,
		RouteNames.ResetPassword,
		RouteNames.Unsubscribe,
		RouteNames.SignUp,
		RouteNames.AdSignIn,
		RouteNames.AdSignUp,
		RouteNames.ADUpdateAccount,
		RouteNames.ADEmailMigration,
	];
	return pagesToCenter.some((p) => p === route.name);
});

const adPages = [
	RouteNames.ADActivation,
	RouteNames.SignInError,
	RouteNames.LastStep,
	RouteNames.AdSignIn,
	RouteNames.AdSignUp,
	RouteNames.ADUpdateAccount,
	RouteNames.ADEmailMigration,
];
const isAdPage = computed(() => adPages.includes(route.name?.toString() as RouteNames));
// Add event listener to intercept clicks on <a> tags
// This event listener will check if the href is internal or not,
// Internal links should make use of the Vue router to avoid refreshes of the page.
document.addEventListener('click', async (ev) => {
	if ((ev.target as HTMLElement | null)?.tagName === 'A') {
		const targetAnchor = ev.target as HTMLAnchorElement;
		// Check if external link and return early if it is.
		if (targetAnchor.host !== document.location.host) {
			return;
		}

		// Otherwise it's an internal link and we want Vue router to take over and prevent default behaviour.
		if (internalNavigationGuard(ev)) {
			ev.preventDefault();
			await router.push(targetAnchor.pathname);
		}
	}
});

// Make sure the Vue router should be used.
const internalNavigationGuard = (ev: MouseEvent) => {
	// don't redirect with control keys
	if (ev.metaKey || ev.altKey || ev.ctrlKey || ev.shiftKey) {
		return false;
	}
	// don't redirect when preventDefault called
	if (ev.defaultPrevented) {
		return false;
	}
	// don't redirect on right click
	if (ev.button !== undefined && ev.button !== 0) {
		return false;
	}
	// don't redirect if `target="_blank"`
	const target = (ev.target as HTMLAnchorElement).getAttribute('target');
	if (target && /\b_blank\b/i.test(target)) {
		return false;
	}

	return true;
};

onBeforeMount(async () => {
	addFathom();
	addCookieYes();
	loadVideoScripts();
});

// #region Global providers for the MemberPortal library

const participantStore = useParticipantStore();

watch(
	() => participantStore.participant,
	() => {
		setCurrentUser(participantStore.participant);
	},
	{ immediate: true },
);

// #endregion
</script>

<style lang="scss">
@import './styles/main';

.q-layout {
	background-color: $background-color;
}

.q-page-container {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

.q-page {
	width: 100%;
}

.q-field--with-bottom {
	padding-bottom: 30px !important;
}

.app-background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	z-index: 0;
}

.non-authorized-footer {
	width: 1080px;
	margin: 0 auto;
	@media (max-width: $breakpoint-md-max) {
		width: 80%;
	}
	@media (max-width: $breakpoint-sm-max) {
		width: 100%;
	}
}

.center-page {
	align-items: center;
	display: flex;
}

.centered-page {
	width: 90%;
}
</style>
