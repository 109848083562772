export enum RouteNames {
	Challenges = 'container.main.challenges',
	ChallengesAll = 'container.main.challenges.all',
	ChallengesCompleted = 'container.main.challenges.completed',
	ChallengesTodo = 'container.main.challenges.todo',
	ChallengesQual = 'container.main.challenges.qualactivity',
	ChallengesNewDisc20 = 'container.main.challenges.discussionNew',

	Footer = 'container.main.footer',

	Forum = 'container.main.forum',

	ForumConversation = 'container.main.forum.conversation',

	ForumNew = 'container.main.forum.new',

	ForumRoom = 'container.main.forum.room',

	DiscussionTopic = 'container.main.forum.discussionTopic',

	DiscussionTopicNew = 'container.main.forum.newDiscussionTopic',

	GotoNotification = 'container.main.gotonotification',

	Helpcenter = 'container.main.helpcenter',
	HelpcenterContact = 'container.main.helpcenter.contact',
	HelpcenterCustom = 'container.main.helpcenter.custom',
	HelpcenterFaq = 'container.main.helpcenter.faq',
	HelpcenterPlatformTour = 'container.main.helpcenter.platformtour',

	Home = 'container.main.home',

	IncentiveLandingpageForm = 'container.main.incentive-landingpage-form',

	Incentives = 'container.main.incentives',
	IncentivesCustom = 'container.main.incentives.custom',
	IncentivesMyIncentives = 'container.main.incentives.myincentive',
	IncentivesMySavingUpRewards = 'container.main.incentives.mysavinguprewards',

	MainpageCustom = 'container.main.custom',

	More = 'container.main.more',

	News = 'container.main.news',
	NewsDetail = 'container.main.news.detail',

	Profile = 'container.main.profile',
	ProfileActivity = 'container.main.profile.activity',
	ProfileEdit = 'container.main.profile.edit',

	PublicProfile = 'container.main.publicProfile',

	Activity = 'container.activity',
	ActivityResearch = 'container.researchactivity',
	ActivityTest = 'container.testactivity',
	ActivityProfileTest = 'container.test-profile-activity',
	ActivityIntake = 'container.intakeactivity',

	AutoLogin = 'container.autologin',

	ChangePassword = 'container.changePassword',

	ConfirmEmail = 'container.confirmEmail',

	ExternalLink = 'container.externallink',

	IntakeFinished = 'container.intakefinished',

	LandingPage = 'container.landingpage',

	LoginError = 'container.loginerror',

	Login = 'container.login',

	SsoLogin = 'container.ssologin',

	Logout = 'container.logout',

	Main = 'container.main',

	Maintenance = 'maintenance',

	NonMain = 'container.nonmain',

	NotFound = 'container.404',

	Reactivate = 'container.reactivate',

	Registration = 'container.registration',

	ResetPassword = 'container.resetPassword',

	SplashPage = 'container.splashPage',

	SSO = 'container.sso',

	ADActivation = 'container.adactivation',
	AdSignIn = 'container.adsignin',
	AdSignUp = 'container.adsignup',
	SignInError = 'container.signinerror',
	LastStep = 'container.migration.laststep',
	ADEmailMigration = 'container.ademailmigration',
	ADUpdateAccount = 'container.adupdateaccount',
	ADMigration = 'container.admigration',

	SignUp = 'container.main.signup',

	Unsubscribe = 'container.unsubscribe',
}
