import { UserEvent, elementClicked, elementViewed } from '@/common/utils/events';
import { ChannelType } from '@/common/constants/enums';
import { useAuthenticationStore } from '@/store/authentication-store';

enum EventType {
	View = 1,
	Click = 2,
}

export const trackResearchActivityCardOnHomePageClick = (communicationSampleGuid: string) => {
	trackActivityChannelEvent(communicationSampleGuid, ChannelType.ResearchActivityChallengeHome, EventType.Click);
};

export const trackResearchActivityCardOnChallengesPageClick = (communicationSampleGuid: string) => {
	trackActivityChannelEvent(communicationSampleGuid, ChannelType.ResearchActivityChallenge, EventType.Click);
};

export const trackPlatformNotificationClick = (sampleGuid: string, channelType: ChannelType | null = null) => {
	trackPlatformNotificationChannelEvent(sampleGuid, channelType, EventType.Click);
};

export const trackResearchActivityNotificationClick = (sampleGuid: string, channelType: ChannelType | null = null) => {
	trackActivityChannelEvent(sampleGuid, channelType || ChannelType.Notification, EventType.Click);
};

export const trackResearchActivityNotificationView = (sampleGuid: string) => {
	trackActivityChannelEvent(sampleGuid, ChannelType.Notification, EventType.View);
};

export const trackResearchActivityCardOnHomePageView = (communicationSampleGuid: string) => {
	trackActivityChannelEvent(communicationSampleGuid, ChannelType.ResearchActivityChallengeHome, EventType.View);
};

export const trackResearchActivityCardOnChallengesPageView = (communicationSampleGuid: string) => {
	trackActivityChannelEvent(communicationSampleGuid, ChannelType.ResearchActivityChallenge, EventType.View);
};

export const trackEngagementCardOnHomePageClick = (targetGuid: string) => {
	const event: UserEvent = {
		identifier: 'engagement-card-channel-',
		channelType: ChannelType.CommunicationCard,
		targetGuid,
	};

	trackEvent(event, EventType.Click);
};

export const trackEngagementCardOnHomePageView = (targetGuid: string) => {
	const event: UserEvent = {
		identifier: 'engagement-card-channel-',
		channelType: ChannelType.CommunicationCard,
		targetGuid,
	};

	trackEvent(event, EventType.View);
};

export const trackNewsCardOnHomePageClick = (targetGuid: string) => {
	trackNewsCardEvent(targetGuid, ChannelType.CommunicationNewsHome, EventType.Click);
};

export const trackNewsCardOnHomePageView = (targetGuid: string) => {
	trackNewsCardEvent(targetGuid, ChannelType.CommunicationNewsHome, EventType.View);
};

export const trackNewsCardOnNewsPageClick = (targetGuid: string) => {
	trackNewsCardEvent(targetGuid, ChannelType.CommunicationNews, EventType.Click);
};

export const trackNewsCardOnNewsPageView = (targetGuid: string) => {
	trackNewsCardEvent(targetGuid, ChannelType.CommunicationNews, EventType.View);
};

const trackNewsCardEvent = (targetGuid: string, channel: ChannelType, eventType: EventType) => {
	const event: UserEvent = {
		identifier: 'news-card-channel-',
		channelType: channel,
		targetGuid,
	};

	trackEvent(event, eventType);
};

const trackActivityChannelEvent = (targetGuid: string, channel: ChannelType, eventType: EventType) => {
	const event: UserEvent = {
		identifier: 'research-activity-channel-',
		channelType: channel,
		targetGuid,
	};

	if (useAuthenticationStore().isMobileApp) {
		event.channelType = ChannelType.ResearchActivityMobileApp;
	}

	trackEvent(event, eventType);
};

const trackPlatformNotificationChannelEvent = (
	targetGuid: string,
	channel: ChannelType | null = null,
	eventType: EventType,
) => {
	const event: UserEvent = {
		identifier: 'platform-notification-channel-',
		channelType: channel,
		targetGuid,
	};

	if (useAuthenticationStore().isMobileApp) {
		event.channelType = ChannelType.ResearchActivityMobileApp;
	}

	trackEvent(event, eventType);
};

const trackEvent = (event: UserEvent, eventType: EventType) => {
	if (eventType === EventType.Click) {
		event.identifier += 'clicked';
		elementClicked(event);
	}

	if (eventType === EventType.View) {
		event.identifier += 'viewed';
		elementViewed(event);
	}
};
