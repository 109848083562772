<script setup lang="ts">
import { IscWrapperPage } from '@ui/components';
import { useConstantsStore } from '@/store/constants-store';
import { computed, onMounted, ref } from 'vue';
import { loadCaptchaIfNecessary, showReCaptchaV2, recaptchaVerified, recaptchaLang } from '@/services/captcha-service';
import { dataLoading, showForgotPasswordLink } from '@/components/login/__composables__/login-composables';
import { RouteNames } from '@/router/routes/route-names.enum';
import { isSsoDomain, startAdminSsoLogin } from '@/common/utils/sso';
import { useVuelidate } from '@vuelidate/core';
import IscEmail from '@ui/components/email/isc-email.vue';
import LoginSubmit from '@/components/login/login-submit.vue';
import loginFormPassword from '@/components/login/login-form-password.vue';
import NavigationBarLogos from '@/components/navigation-menu/navigation-bar-logos.vue';
import { emptyImage, useImagesStore } from '@/store/images-store';
import { RECAPTCHAV2_SITE_ID } from '@/common/env.config';
import vueRecaptcha from 'vue3-recaptcha2';

const getLabelValue = useConstantsStore().getLabelValue;
const v$ = useVuelidate();

const email = ref('');
const ssoDomain = ref(false);

const loginSsoDomain = async () => {
	ssoDomain.value = await isSsoDomain(email.value);
	showForgotPasswordLink.value = !ssoDomain.value;
	if (ssoDomain.value) {
		startAdminSsoLogin();
	}
};

const imagesStore = useImagesStore();
const images = computed(() => imagesStore.$state);

onMounted(async () => {
	await loadCaptchaIfNecessary('login');
	dataLoading.value = false;
});

// Define reactive data
const loadingTimeout = ref(60000); // 60 seconds
</script>

<template>
	<div class="login-page q-mx-auto">
		<isc-wrapper-page
			:top-margin="false"
			:x-padding="false"
			:y-padding="false"
		>
			<form @submit.prevent>
				<navigation-bar-logos
					:square-logo-clickable="false"
					class="q-pa-lg"
				/>
				<div
					:class="{
						'q-pa-md': $q.screen.lt.md,
						'q-px-xl q-pb-xl': !$q.screen.lt.md,
						'q-pt-xl': images.logoTopBar === emptyImage,
						'q-pt-lg': images.logoTopBar !== emptyImage,
					}"
				>
					<h2 class="login-page__title text-center text-primary q-mt-none text-uppercase text-h5">
						{{ getLabelValue('LabelWindowTitleLogin') }}
					</h2>
					<isc-email
						v-model="email"
						:email-label="getLabelValue('LabelAccountEmail')"
						:autocomplete="false"
						@update:model-value="loginSsoDomain"
					/>
					<login-form-password />
					<div
						v-if="showForgotPasswordLink"
						class="text-right"
					>
						<q-btn
							:label="getLabelValue('LabelForgotPassword')"
							:to="{ name: RouteNames.ResetPassword }"
							flat
							no-caps
							class="login-page__forgot-password text-on-surface-medium text-caption q-px-sm"
						/>
					</div>
					<vue-recaptcha
						v-if="showReCaptchaV2"
						:sitekey="RECAPTCHAV2_SITE_ID"
						size="normal"
						theme="light"
						:hl="recaptchaLang"
						:loading-timeout="loadingTimeout"
						class="q-mb-md"
						@verify="recaptchaVerified"
					/>
					<login-submit
						:invalid-fields="v$.$invalid"
						:email="email"
						@start-admin-sso-login="startAdminSsoLogin"
					/>
				</div>
			</form>
		</isc-wrapper-page>
	</div>
</template>

<style lang="scss">
.login-page {
	max-width: 412px;
}
</style>
