import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const LastStepPage = () => import('@/pages/last-step-page.vue');

export const lastStepRoute: RouteRecordRaw = {
	name: RouteNames.LastStep,
	path: '/laststep',
	component: LastStepPage,
	meta: {
		title: '',
		requireLogin: false,
		hideFooter: true,
	},
};
