import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

export const maintenanceRoute: RouteRecordRaw = {
	name: RouteNames.Maintenance,
	path: '/maintenance?:goto',
	component: getWrappedAngularComponent('maintenanceComponent'),
	meta: {
		requireLogin: false,
		title: 'Maintenance',
	},
};
