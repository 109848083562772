import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { guidRegex } from '@/router/router.constants';

const ReactivatePage = () => import('@/pages/reactivate-page.vue');

export const reactivateRoute: RouteRecordRaw = {
	name: RouteNames.Reactivate,
	path: `/reactivate/:subscribeGuid(${guidRegex})`,
	component: ReactivatePage,
	meta: {
		requireLogin: false,
		title: '(LabelWindowTitleReactivate)',
	},
};
