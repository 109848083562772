<template>
	<div
		v-show="showSquareNotActive"
		class="square-not-active"
	>
		{{ message }}
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { Role, SquareStatus } from '@/common/constants/enums';

const showSquareNotActive = computed(
	() => !useSquareStore().isActive && useAuthenticationStore().tokenInfo?.role !== Role.Participant,
);
const squareStatus = useSquareStore().info.status;
let message = '';
if (squareStatus === SquareStatus.Scheduled) {
	message = 'This square is not active yet';
} else if (squareStatus === SquareStatus.Closed) {
	message = 'This square is currently closed';
} else if (squareStatus === SquareStatus.Archived) {
	message = 'This square is currently archived';
}
</script>

<style lang="scss">
.square-not-active {
	background-color: $red-14;
	color: $light;
	font-size: 14px;
	line-height: 40px;
	font-weight: bold;
	align-items: center;
	justify-content: center;
	display: block;
	text-align: center;
	word-wrap: break-word;
	padding: padding-inline-values(0 20px);
}
</style>
