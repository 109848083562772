import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';

export const incentiveLandingpageFormRoute: RouteRecordRaw = {
	name: RouteNames.IncentiveLandingpageForm,
	path: `/getreward/:rewardWinnerGuid(${guidRegex})`,
	component: () => import('@/pages/incentives/incentive-landing-page.vue'),
	meta: {
		requireLogin: true,
		title: '(LabelWindowTitleIncentivesLandingForm)',
		mobileTitle: '(LabelIncentiveLandingpageFormText)',
	},
};
