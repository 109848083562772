import { useConstantsStore } from '@/store/constants-store';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';

export default (to: RouteLocationNormalized): NavigationGuardReturn => {
	const constantsStore = useConstantsStore();

	let title = typeof to.meta.title === 'function' ? to.meta.title(to.params) : (to.meta.title ?? '');
	const labelToTranslate = /\((\w+)\)/g.exec(title)?.[1];
	if (labelToTranslate) {
		title = constantsStore.getLabelValue(labelToTranslate, to.meta?.replacements);
	}
	document.title = `Square: ${title}`;
};
