<script lang="ts" setup>
import { PageType } from '@/common/constants/enums';
import HeaderImages from '@/components/header-images/header-images.vue';
import { RouteNames } from '@/router/routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useQuasar } from 'quasar';
import { computed, StyleValue } from 'vue';
import { useRoute } from 'vue-router';
import { useConstantsStore } from '@/store/constants-store';

const route = useRoute();
const $q = useQuasar();
const authStore = useAuthenticationStore();
const squareStore = useSquareStore();
const homePage = computed(() => usePagesStore().mainPages.find((p) => p.pageType === PageType.Home));

// To use set narrow: true in the meta of the route on the child route
const narrowPage = computed(() => route.meta.narrow);
const nonMainRoute = computed(() => route.matched[0].name === RouteNames.NonMain);
const constantsStore = useConstantsStore();
const currentPageTitle = computed(() => {
	const title = typeof route.meta.title === 'function' ? route.meta.title(route.params) : (route.meta.title ?? '');
	const labelToTranslate = /\((\w+)\)/g.exec(title)?.[1];
	if (labelToTranslate) {
		return constantsStore.getLabelValue(labelToTranslate, route.meta?.replacements);
	}
	return title;
});

// Only show header images when:
// 1) Is authorized
// 2) Is not on mobile app
// 3) Is on desktop layout or on the home page on mobile
// 4) Isn't a main site page (e.g. login/splash)
const shouldShowHeaderImages = computed(
	() =>
		authStore.isAuthorized &&
		!authStore.tokenInfo?.isMobileAppUser &&
		($q.screen.gt.sm || route.name?.toString() === RouteNames.Home) &&
		nonMainRoute.value === false,
);

// set page specific design
const primaryColor = computed(() => {
	const design = squareStore.design;
	const baseColor = design.squareDesign?.baseColor1;

	if (route.params && route.params.pageGuid) {
		return squareStore.design.pageDesign?.find((p) => p.pageGuid === route.params.pageGuid)?.color || baseColor;
	}

	if (route.name === RouteNames.Home) {
		return design.pageDesign?.find((p) => p.pageGuid === homePage.value?.guid)?.color || baseColor;
	}

	return baseColor;
});

const routeKey = computed(() => {
	// We don't want the page to completely reload whenever the activityGuid on the Vue profile page changes.
	// The page component will take care of that itself.
	const matchedRouteNames = route.matched.map((r) => r.name);
	if (matchedRouteNames.includes(RouteNames.Profile)) {
		return '';
	}
	// This is needed for Angular pages to work correctly
	return `${route.params.pageGuid}-${route.params.activityGuid}`;
});

const style = computed(() => ({ '--q-primary': primaryColor.value }) as StyleValue);
</script>

<template>
	<header-images
		v-if="shouldShowHeaderImages"
		:style="style"
	/>
	<div
		id="main"
		tabindex="-1"
		class="main-content q-mx-auto q-my-none relative-position"
		:style="style"
		:class="{
			'q-pb-md': !nonMainRoute && $q.screen.lt.md,
			'q-pb-xl': !nonMainRoute && !$q.screen.lt.md,
			'q-px-md': $q.screen.lt.md,
			'q-pt-md': !nonMainRoute,
			'column justify-center': nonMainRoute,
			'main-content__narrow': narrowPage,
		}"
	>
		<h1 class="text-h6">
			{{ currentPageTitle }}
		</h1>
		<!-- Because we want pages to reload whenever their page guid or activity guid changes even if they use the same route -->
		<router-view
			:key="String(routeKey)"
			:class="{ 'main-content__page': nonMainRoute && !$q.screen.lt.md }"
		/>
	</div>
</template>

<style lang="scss">
$mask-settings: url('/images/isc-logo.svg') no-repeat 50% 95%;
$mask-size: 100px;

.main-content {
	// Needed to match width from Angular
	// In Angular we had font size 18px and width 60em (= 1080px)
	// The reason we do it different here is because setting the font size here would mess with our Quasar components
	width: 1080px;
	flex-grow: 1;

	&__narrow {
		width: 412px;
	}
	&__page {
		z-index: 1;
	}
	@media (max-width: $breakpoint-md-max) {
		width: 80%;
	}
	@media (max-width: $breakpoint-sm-max) {
		width: 100%;
	}

	& > h1 {
		position: fixed;
		top: -100px;
	}
}
</style>
