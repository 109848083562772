import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { LocationQueryRaw, RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { isSupportedMobileDeviceOS } from './force-mobile-app-guard';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	const authStore = useAuthenticationStore();
	const squareStore = useSquareStore();
	const isLoginRequiredForRoute = to.meta?.requireLogin;
	if (
		isLoginRequiredForRoute &&
		!authStore.isAuthorized /* Only redirect to login page if we aren't authorized yet */
	) {
		// Add a from query parameter to be redirected to after login
		// We don't do this for the home page since that's the default redirect if the from query parameter isn't filled in.
		const query: LocationQueryRaw = to.name !== RouteNames.Home ? { from: to.path } : {};
		if (squareStore.info.adLogin) {
			if (to.query.token) {
				// we need to set the token to the sso login page, otherwise we`re in a loop
				query.token = to.query.token.toString();
			}
		}
		// for AD login:
		// - if mobile, return default route, SSO
		// - otherwise, if square info says show verify its you, return AD registration route (verify its you)
		//              if its disabled, return default route, SSO
		const routeName = squareStore.info.adLogin
			? isSupportedMobileDeviceOS()
				? RouteNames.SSO
				: RouteNames.AdSignIn
			: RouteNames.Login;

		return {
			name: routeName,
			query,
		};
	}

	return true;
};
