import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useParticipantStore } from '@/store/participant-store';
import { Role } from '@/common/constants/enums';
import { hideMigrationDialog } from '../global-guards/migration-guard';

const AdUpdateAccountPage = () => import('@/pages/ad-updateaccount-page.vue');

export const adUpdateAccountRoute: RouteRecordRaw = {
	name: RouteNames.ADUpdateAccount,
	path: '/updateaccount',
	component: AdUpdateAccountPage,
	meta: {
		title: '',
		requireLogin: true,
		hideFooter: false,
	},
	beforeEnter: () => {
		// we allow routing only if AD square but not AD user yet
		const squareStore = useSquareStore();
		const authStore = useAuthenticationStore();
		if (!squareStore.info.adLogin || !authStore.isAuthorized || authStore.isAdUser) {
			return { name: RouteNames.Home };
		}
		const participantStore = useParticipantStore();
		if (participantStore.participant.role === Role.Human8) {
			return { name: RouteNames.Home };
		}
		hideMigrationDialog();
		return true;
	},
};
