import { getCacheValue } from '@ui/common/utils/cache';
import { useImagesStore } from '@/store/images-store';

interface ImagesFactory {
	applyImages: () => void;
	images: ImageDetails;
}

interface ImageDetails {
	logoTopBar: string;
	headerLogo: string;
	headerDesktop: string;
	headerMobile: string;
	background: string;
}

export default new Proxy(
	{
		async applyImages(): Promise<void> {
			const images = JSON.parse(getCacheValue<string>('Images') || '[]');
			await useImagesStore().applyImages(images);
		},
	},
	{
		get: (target, key: keyof ImagesFactory) => {
			if (key !== 'applyImages') {
				return useImagesStore().$state;
			}

			return Reflect.get(target, key);
		},
		set: (_, key: keyof ImagesFactory, value) => {
			if (key === 'applyImages') {
				return false;
			}
			useImagesStore().$state = value;
			return true;
		},
	},
);
