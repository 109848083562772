/* eslint-disable */
// @ts-nocheck
interface AppInsights {
	clearAuthenticatedUserContext: () => void;
	config: { instrumentationKey: string };
	cookie: string;
	flush: () => void;
	queue: [];
	setAuthenticatedUserContext: (...params: string[]) => void;
	startTrackEvent: () => void;
	startTrackPage: () => void;
	stopTrackEvent: () => void;
	stopTrackPage: () => void;
	trackDependency: () => void;
	trackEvent: () => void;
	trackException: () => void;
	trackMetric: () => void;
	trackPageView: (
		name: string | null,
		url: string | null,
		properties: Record<string, unknown> | null,
		measurements: Record<string, number> | null,
		duration: number,
	) => void;
	trackTrace: () => void;
	version: string;
	_onerror: () => void;
}

export const appInsights = function (config): AppInsights {
	function i(config) {
		t[config] = function () {
			const i = arguments;
			t.queue.push(function () {
				t[config].apply(t, i);
			});
		};
	}
	const t = { config };
	const u = document;
	const e = window;
	const o = 'script';
	const s = 'AuthenticatedUserContext';
	const h = 'start';
	const c = 'stop';
	const l = 'Track';
	const a = `${l}Event`;
	const v = `${l}Page`;
	const y = u.createElement(o);
	let r;
	let f;
	y.src = config.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js';
	u.getElementsByTagName(o)[0].parentNode.appendChild(y);
	try {
		t.cookie = u.cookie;
	} catch (p) {}
	for (
		t.queue = [], t.version = '1.0', r = ['Event', 'Exception', 'Metric', 'PageView', 'Trace', 'Dependency'];
		r.length;

	) {
		i(`track${r.pop()}`);
	}
	return (
		i(`set${s}`),
		i(`clear${s}`),
		i(h + a),
		i(c + a),
		i(h + v),
		i(c + v),
		i('flush'),
		config.disableExceptionTracking ||
			((r = 'onerror'),
			i(`_${r}`),
			(f = e[r]),
			(e[r] = function (config, i, u, e, o) {
				const s = f && f(config, i, u, e, o);
				return s !== !0 && t[`_${r}`](config, i, u, e, o), s;
			})),
		t
	);
};
