<template>
	<user-notification-base
		:notifications="userNotifications"
		:offset="menuOffset"
		@click="trackResearchActivityNotificationsView()"
		@notification-clicked="notificationClicked"
	/>
</template>

<script setup lang="ts">
import { trackResearchActivityNotificationView } from '@/common/utils/tracking';
import { useNotificationsStore } from '@/store/notifications-store';
import { computed } from 'vue';
import { NotificationCategory } from '@/common/constants/enums';
import { UserNotificationBase } from '@ui/components';
import * as notifications from '@/common/utils/notifications';
import { useAuthenticationStore } from '@/store/authentication-store';
import { UserNotificationResponse } from '@api/models/query';

const viewedNotifications: UserNotificationResponse[] = [];
const menuOffset = useAuthenticationStore().isMobileApp ? [0, 30] : [0, 30];

const userNotifications = computed(() => useNotificationsStore().userNotifications);

const notificationClicked = (notificationGuid: string) => {
	const notification = userNotifications.value.find((not) => not.guid === notificationGuid);

	if (notification) {
		notifications.notificationClicked(notification);
	}
};

const trackResearchActivityNotificationsView = () => {
	userNotifications.value
		.filter((item) => item.category === NotificationCategory.Activity && !viewedNotifications.includes(item))
		.forEach(async (item) => {
			viewedNotifications.push(item);
			const notificationTargetGuid = await notifications.resolveNotificationTargetGuid(item);
			if (notificationTargetGuid) {
				trackResearchActivityNotificationView(notificationTargetGuid);
			}
		});
};
</script>
