import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';
import { usePagesStore } from '@/store/pages-store';

const CustomPage = () => import('@/pages/custom-page.vue');

export const footerpageRoute: RouteRecordRaw = {
	name: RouteNames.Footer,
	path: `/footer/:pageGuid(${guidRegex})`,
	component: CustomPage,
	meta: {
		requireLogin: false,
		title: (routeParams: RouteParams) => {
			const pagesStore = usePagesStore();
			return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleFooter)';
		},
	},
};
