<script setup lang="ts">
import { SampleStatus } from '@/common/constants/enums';
import { computed } from 'vue';
import { useSquareStore } from '@/store/square-store';
import { useParticipantStore } from '@/store/participant-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useNotificationsStore } from '@/store/notifications-store';
import { IscBadgeWrapper } from '@ui/components';
import { useConstantsStore } from '@/store/constants-store';

// square info
const squareStore = useSquareStore();
const squareInfo = squareStore.info;

// labels
const constantsStore = useConstantsStore();
const getLabelValue = constantsStore.getLabelValue;

// participant info
const participantStore = useParticipantStore();
const participant = computed(() => participantStore.participant);
const participantAvatar = computed(() => participant.value.avatar);

// incentives
const notificationsStore = useNotificationsStore();

const authStore = useAuthenticationStore();
const todoProfiles = computed(() =>
	notificationsStore.profileActivities.filter((activity) => activity.sampleStatus !== SampleStatus.Completed),
);
const isImpersonating = computed(() => !!authStore.impersonate);
const onBehalfOfUsername = computed(() => authStore.impersonate?.username || '');
</script>

<template>
	<div
		v-if="!squareInfo.ssoEnabled"
		class="profile-logo"
		data-v-step="profile"
	>
		<router-link
			:to="{ name: RouteNames.Profile }"
			class="row justify-center items-center"
			:aria-label="getLabelValue('LabelProfileMyProfile')"
		>
			<div class="profile-logo__picture">
				<q-avatar
					v-if="participant?.hasProfileImage"
					:size="$q.screen.lt.md ? 'xl' : 'lg'"
					round
				>
					<q-img
						:src="participantAvatar"
						class="border-radius-inherit"
						alt=""
						:height="$q.screen.lt.md ? '26px' : '21px'"
						:width="$q.screen.lt.md ? '26px' : '21px'"
					/>
					<isc-badge-wrapper
						v-if="todoProfiles.length"
						:notifications="todoProfiles.length"
					/>
				</q-avatar>
				<q-avatar
					v-else
					icon="fas fa-circle-user"
					padding="sm"
					:size="$q.screen.lt.md ? 'xl' : 'lg'"
					round
				>
					<isc-badge-wrapper
						v-if="todoProfiles.length"
						:notifications="todoProfiles.length"
					/>
				</q-avatar>
			</div>
			<div
				v-if="$q.screen.gt.sm"
				class="col"
			>
				<div
					v-if="!isImpersonating"
					class="profile-logo__profile-name q-pa-sm ellipsis"
				>
					<span class="text-subtitle2 text-uppercase">{{ participant?.firstName || '' }}</span>
				</div>
				<div
					v-else
					class="profile-logo__name q-pa-sm"
				>
					<span class="text-grey profile-logo__behalfof-text">Posting on behalf of</span>
					<span class="text-subtitle2 profile-logo__behalfof-username text-uppercase">{{ onBehalfOfUsername }}</span>
				</div>
			</div>
		</router-link>
	</div>
</template>

<style lang="scss">
.profile-logo {
	.profile-logo__behalfof-text {
		display: block;
		font-size: small;
	}

	@media (max-width: $breakpoint-sm-max) {
		*:not(.q-badge, .square-not-active) {
			color: var(--navigation-bar-text, $dark);
		}
		a {
			text-decoration: none;
		}
	}
}
</style>
