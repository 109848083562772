import { App, Plugin } from 'vue';
import { setLabelFunction } from './services/label-service';
import '../../../FontAwesome/css/all.css';

// install function executed by Vue.use()
const install: Exclude<Plugin['install'], undefined> = function installVueSquareUi(
	_app: App,
	options: { labels: () => Record<string, string> },
) {
	setLabelFunction(options.labels);
};

// Create module definition for Vue.use()
export default install;
