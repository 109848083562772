<template>
	<div class="desktop-footer gt-sm column q-mx-auto q-py-md">
		<div class="row wrap justify-center">
			<div
				v-for="page in pages"
				:key="page.guid"
				class="desktop-footer__footer-tabs"
			>
				<a
					v-if="page.isExternal"
					class="q-px-md text-caption text-human8-black"
					:href="page.link"
					:target="page.target"
					:aria-label="`${page.name} - ${getLabelValue('NewWindowLabel', 'New Window')}`"
				>
					{{ page.name }}
				</a>
				<router-link
					v-else
					class="q-px-md text-caption text-human8-black"
					:class="{
						'cky-banner-element': page.pageType === PageType.MyCookieSettings,
					}"
					:to="page.link || ''"
				>
					{{ page.name }}
				</router-link>
			</div>
			<div
				v-if="showIcpLicense"
				class="desktop-footer__footer-tabs"
			>
				<a
					class="q-px-md text-caption text-human8-black"
					:href="icpRedirectUrl"
					target="_blank"
					:aria-label="`${icpLicenseNumber} - ${getLabelValue('NewWindowLabel', 'New Window')}`"
				>
					{{ icpLicenseNumber }}
				</a>
			</div>
		</div>
		<div class="row wrap justify-center">
			<div class="q-mx-lg q-mt-md">
				<footer-logo class="desktop-footer__logo" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { usePagesStore } from '@/store/pages-store';
import { getLabelValue } from '@ui/services/label-service';
import { computed } from 'vue';
import { ICP_LICENSE_NUMBER, ICP_REDIRECT_URL, SHOW_ICP_LICENSE, COOKIEYES_SITE_ID } from '@/common/env.config';
import { useRouter } from 'vue-router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { PageType } from '@/common/constants/enums';
import FooterLogo from '@/components/navigation-menu/footer-logo.vue';

const router = useRouter();
const pages = computed(() =>
	usePagesStore()
		.footerPages.filter((page) => {
			if (COOKIEYES_SITE_ID === '' && page.pageType === PageType.MyCookieSettings) {
				return false;
			}
			return true;
		})
		.map((page) => ({
			...page,
			// Check which pages are external by trying to resolve the link attached to the page.
			// If the resolved route is the Not Found route, we assume it's an external page.
			isExternal: page.link && router.resolve({ path: page.link }).name === RouteNames.NotFound,
		})),
);
const showIcpLicense = SHOW_ICP_LICENSE;
const icpRedirectUrl = ICP_REDIRECT_URL;
const icpLicenseNumber = ICP_LICENSE_NUMBER;
</script>

<style lang="scss">
.desktop-footer {
	border: 1px solid $border-color;
	background-color: #ffffff;
	border-radius: 4px 4px 0 0;
	color: #858585;
	align-items: center;
	justify-content: center;
	width: 1080px;
	margin: 0 auto;
	@media (max-width: $breakpoint-md-max) {
		width: 80%;
	}
	@media (max-width: $breakpoint-sm-max) {
		width: 100%;
	}
}

.desktop-footer__footer-tabs {
	a {
		text-decoration: none;
	}
}
</style>
