import { isMasterFeatureEnabledForSquare } from '@/services/feature-service';
import { RouteNames } from '@/router/routes/route-names.enum';
import { Feature } from '@/common/constants/enums';
import router from '@/router';
import { groupBy } from 'lodash-es';
import { FormError, ErrorData } from '@/common/types/form-errors.types';
import { getLabelValue } from '@ui/services/label-service';

export const checkAzureAdB2CLogin = async () => {
	// If the AD feature is enabled, participants can only change their password in the Azure AD
	if (await isMasterFeatureEnabledForSquare(Feature.AzureAdB2CLogin)) {
		router.push({ name: RouteNames.Home });
	}
};

export const processServerFormErrors = (error: FormError) => {
	// Group by property name in case there is more than 1 error for that property
	// Ideally we should already group them in the backend
	const grouped = groupBy(error.data.validationErrors, 'propertyName');
	const messages: ErrorData[] = [];
	for (const [key, items] of Object.entries(grouped)) {
		const message = items.map((item) => item.errorMessage).join(' ');
		messages.push({ propertyName: key, errorMessage: message });
	}
	return messages;
};

export const processAndTranslateServerFormErrors = (error: FormError) => {
	const grouped = groupBy(error.data.validationErrors, 'propertyName');
	const messages: ErrorData[] = [];
	for (const [key, items] of Object.entries(grouped)) {
		const message = items.map((item) => getLabelValue(item.errorMessage, 'An error has happened')).join(' ');
		messages.push({ propertyName: key, errorMessage: message });
	}
	return messages;
};
