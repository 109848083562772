import { LocationQuery, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import LandingPage from '@/pages/landing-page.vue';
import { useSquareStore } from '@/store/square-store';

export const landingPageRoute: RouteRecordRaw = {
	name: RouteNames.LandingPage,
	path: '/landingpage',
	component: LandingPage,
	meta: {
		requireLogin: false,
	},
	beforeEnter: (to) => {
		const queryParamsDefaults: LocationQuery = {
			landingTitle: 'LabelLandingPageTitleDefault',
			landingMessage: 'LabelLandingPageMessageDefault',
			redirectState: useSquareStore().info.adLogin ? RouteNames.Home : RouteNames.Login,
			redirectToMobileAppHome: 'false',
			redirectParams: '',
			timeout: '5000',
			isExternalLink: 'false',
			isCookiePage: 'false',
		};
		to.query = {
			...queryParamsDefaults,
			...to.query,
			...to.params,
		};
	},
};
