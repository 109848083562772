// We have to override our promises so that the Angular digest cycle gets triggered whenever a promise finishes.
// Only the 'then' method has to be overridden since the 'catch' and 'finally' methods call this internally.
const originalThen = Promise.prototype.then;

export const overridePromises = [
	'$rootScope',
	($scope: ng.IRootScopeService) => {
		// @ts-expect-error These don't have the exact same syntax.
		Promise.prototype.then = function (...args) {
			const actualThen = originalThen.call(this, ...args);
			try {
				originalThen.call(
					actualThen,
					() => $scope.$evalAsync(),
					() => $scope.$evalAsync(),
				);
			} catch {
				// If for some reason something goes wrong with starting the digest cycle, continue anyway.
			}
			return actualThen;
		};
	},
];
