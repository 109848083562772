import { ChannelType, NotificationCategory, NotificationType, SquareActivityType } from '@/common/constants/enums';
import { jumpToReplyForum, jumpToReplyQual } from '@/common/utils/conversation';
import { trackPlatformNotificationClick, trackResearchActivityNotificationClick } from '@/common/utils/tracking';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import { PageType } from '@ui/common/constants/enums';
import { getQualSampleGuidFromReply } from '@api/services/command/default/ForumService';
import { UserNotificationResponse } from '@api/models/query';
import { useNotificationsStore } from '@/store/notifications-store';

// eslint-disable-next-line complexity
export const notificationClicked = async (notification: UserNotificationResponse) => {
	const channelType = parseInt(router.currentRoute.value.query.channelType as string, 10) || 0;
	await useNotificationsStore().markNotificationRead(notification.guid, notification.relatedNotificationGuids);
	const notificationTargetGuid = await resolveNotificationTargetGuid(notification);

	switch (notification.notificationType) {
		case NotificationType.PlatformNotification: {
			if (notification.targetGuid) {
				trackPlatformNotificationClick(notification.targetGuid, channelType);
			}
			window.open(notification.redirectToUrl, '_self');
			break;
		}
		case NotificationType.ProfileActivityAvailable: {
			router.push({
				name: RouteNames.ProfileActivity,
				params: { activityGuid: notificationTargetGuid },
			});
			break;
		}
		case NotificationType.QuantResearchActivityAvailable:
		case NotificationType.QuantResearchAlmostClosed:
		case NotificationType.QualResearchActivityAvailable:
		case NotificationType.QualResearchAlmostClosed:
		case NotificationType.FailedStimuliUpload:
		case NotificationType.NextActivityInSequenceLaunched: {
			const route = {
				name: RouteNames.Activity,
				params: {
					sampleGuid: notificationTargetGuid,
					channel: ChannelType.Notification,
				},
			};
			if (notification.activityType === SquareActivityType.CheckMarketSurvey) {
				const url = router.resolve(route);
				window.open(location.origin + url.fullPath, '_blank');
			} else if (
				notification.activityType === SquareActivityType.Discussion20Research ||
				notification.activityType === SquareActivityType.Observation
			) {
				const pagesStore = usePagesStore();
				const challengesPage = pagesStore.mainPages.find((item) => item.pageType === PageType.Activities);
				router.push({
					name: RouteNames.ChallengesNewDisc20,
					params: {
						activityGuid: notification.activityGuid,
						channel: ChannelType.Notification,
						pageGuid: challengesPage?.guid,
					},
				});
			} else {
				router.push(route);
			}
			break;
		}
		case NotificationType.DiscussionMention:
		case NotificationType.DiscussionReply:
		case NotificationType.DiscussionLike:
		case NotificationType.DiscussionMentions:
		case NotificationType.DiscussionReplies:
		case NotificationType.DiscussionLikes:
		case NotificationType.DiscussionMentionReplyLike:
		case NotificationType.DiscussionMentionRepliesLike:
		case NotificationType.DiscussionMentionReplyLikes:
		case NotificationType.DiscussionMentionRepliesLikes:
		case NotificationType.DiscussionMentionsReplyLike:
		case NotificationType.DiscussionMentionsRepliesLike:
		case NotificationType.DiscussionMentionsReplyLikes:
		case NotificationType.DiscussionMentionsRepliesLikes:
		case NotificationType.DiscussionMentionReply:
		case NotificationType.DiscussionMentionReplies:
		case NotificationType.DiscussionMentionsReply:
		case NotificationType.DiscussionMentionsReplies:
		case NotificationType.DiscussionMentionLike:
		case NotificationType.DiscussionMentionLikes:
		case NotificationType.DiscussionMentionsLike:
		case NotificationType.DiscussionMentionsLikes:
		case NotificationType.DiscussionReplyLike:
		case NotificationType.DiscussionRepliesLike:
		case NotificationType.DiscussionReplyLikes:
		case NotificationType.DiscussionRepliesLikes:
		case NotificationType.NewProbingQuestion:
		case NotificationType.NewReplyWithAnswerRequired:
		case NotificationType.ReplyAccepted:
		case NotificationType.NewOptionalProbingQuestion:
		case NotificationType.QualResearchActivityUpdate: {
			if (notification.targetGuid) {
				await jumpToReplyQual(
					notification.targetGuid,
					ChannelType.Notification,
					null,
					null,
					notification.activityGuid,
					notification.isDiscussionNew,
				);
			}
			break;
		}
		case NotificationType.ForumMention:
		case NotificationType.ForumReply:
		case NotificationType.ForumLike:
		case NotificationType.ForumMentions:
		case NotificationType.ForumReplies:
		case NotificationType.ForumLikes:
		case NotificationType.ForumMentionReplyLike:
		case NotificationType.ForumMentionRepliesLike:
		case NotificationType.ForumMentionReplyLikes:
		case NotificationType.ForumMentionRepliesLikes:
		case NotificationType.ForumMentionsReplyLike:
		case NotificationType.ForumMentionsRepliesLike:
		case NotificationType.ForumMentionsReplyLikes:
		case NotificationType.ForumMentionsRepliesLikes:
		case NotificationType.ForumMentionReply:
		case NotificationType.ForumMentionReplies:
		case NotificationType.ForumMentionsReply:
		case NotificationType.ForumMentionsReplies:
		case NotificationType.ForumMentionLike:
		case NotificationType.ForumMentionLikes:
		case NotificationType.ForumMentionsLike:
		case NotificationType.ForumMentionsLikes:
		case NotificationType.ForumReplyLike:
		case NotificationType.ForumRepliesLike:
		case NotificationType.ForumReplyLikes:
		case NotificationType.ForumRepliesLikes:
		case NotificationType.NewLoungeTopic: {
			if (notification.targetGuid) {
				await jumpToReplyForum(notification.targetGuid);
			}
			break;
		}
		case NotificationType.SavingUpRewardsEarned:
		case NotificationType.SavingUpRewardsCorrectionApplied:
		case NotificationType.RewardsAvailableForRedemption:
		case NotificationType.MoreSavingUpRewardsEarned:
		case NotificationType.SquareParticipantAlmostInactive: {
			router.push({ name: RouteNames.IncentivesMySavingUpRewards });
			break;
		}
	}

	if (notificationTargetGuid && notification.category === NotificationCategory.Activity) {
		trackResearchActivityNotificationClick(notificationTargetGuid, channelType);
	}
};

export const resolveNotificationTargetGuid = async (notification: UserNotificationResponse) => {
	let targetGuid = notification.targetGuid;

	if (
		notification.targetGuid &&
		(notification.notificationType === NotificationType.DiscussionReply ||
			notification.notificationType === NotificationType.NewReplyWithAnswerRequired ||
			notification.notificationType === NotificationType.ReplyAccepted ||
			notification.notificationType === NotificationType.DiscussionLike ||
			notification.notificationType === NotificationType.DiscussionMention)
	) {
		targetGuid = await getQualSampleGuidFromReply({
			replyGuid: notification.targetGuid,
		});
	}
	return targetGuid;
};
