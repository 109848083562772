const envVars = window as unknown as ImportMetaEnv;

/** Absolute URL */
export const AUTHENTICATION_BASE = envVars.AUTHENTICATION_BASE;
/** Absolute URL formatted as: `${AUTHENTICATION_BASE}?return_uri=` */
export const AUTHENTICATION_URL = envVars.AUTHENTICATION_URL;
/** Absolute URL */
export const AZURE_BASE_URL = envVars.AZURE_BASE_URL;
/** Relative URL */
export const BASE_URL = envVars.BASE_URL;
export const BUILD_VERSION = envVars.BUILD_VERSION;
/** Relative URL (Absolute URL on localhost) */
export const COMMAND_API_URL = envVars.COMMAND_API_URL;
/** Absolute URL */
export const DECIPHER_URL = envVars.DECIPHER_URL;
/** Absolute URL */
export const HEALTHDASHBOARD_URL = envVars.HEALTHDASHBOARD_URL;
export const ENABLE_DEBUG = envVars.ENABLE_DEBUG;
export const ENVIRONMENT = envVars.ENVIRONMENT;
/** Absolute URL */
export const INSITES_URL = envVars.INSITES_URL;
export const INSTRUMENTATION_KEY = envVars.INSTRUMENTATION_KEY;
export const IS_ENVIRONMENT_AS_PREFIX = envVars.IS_ENVIRONMENT_AS_PREFIX;
export const JWT_KEY = envVars.JWT_KEY;
/** Relative URL (Absolute URL on localhost) */
export const QUERY_API_URL = envVars.QUERY_API_URL;
/** Absolute URL */
export const MEMBER_API_URL = envVars.MEMBER_API_URL;
/** Relative URL (Absolute URL on localhost) */
export const SIGNALR_URL = envVars.SIGNALR_URL;
/** Absolute URL without protocol (e.g. `dev-insiteseco.com`) */
export const SQUARE_URL_BASE = envVars.SQUARE_URL_BASE;
/** Absolute URL */
export const ICP_REDIRECT_URL = envVars.ICP_REDIRECT_URL;
export const SHOW_ICP_LICENSE = envVars.SHOW_ICP_LICENSE;
export const ICP_LICENSE_NUMBER = envVars.ICP_LICENSE_NUMBER;
/** Absolute URL */
export const FATHOM_URL = envVars.FATHOM_URL;
export const FATHOM_SITE_ID = envVars.FATHOM_SITE_ID;
export const CAPTCHA_METHOD = envVars.CAPTCHA_METHOD;
export const RECAPTCHA_FALLBACKMODE = envVars.RECAPTCHA_FALLBACKMODE;
export const RECAPTCHAV3_SITE_ID = envVars.RECAPTCHAV3_SITE_ID;
export const RECAPTCHAV2_SITE_ID = envVars.RECAPTCHAV2_SITE_ID;
export const COOKIEYES_SITE_ID = envVars.COOKIEYES_SITE_ID;
