import router from '@/router';
import { SsoRedirectHome, SsoRedirectToScreener } from './sso.types';
import { AUTHENTICATION_BASE, AUTHENTICATION_URL } from '@/common/env.config';
import { useSquareStore } from '@/store/square-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { Dialog } from 'quasar';
import { RouteLocationNormalized } from 'vue-router';
import { squareParticipantSso } from '@/services/auth-service';
import { memoize } from 'lodash-es';
import { getScreenerSurveyLink } from '@api/services/command/default/ActivityService';
import { isSsoDomain as isSsoDomainApi } from '@api/services/command/default/AuthorizationService';
import { SocialLoginType } from '@api/models/query';

export const startAdminSsoLogin = () => {
	const ssoRoute = router.resolve({ name: RouteNames.SSO });
	const returnUri = new URL(ssoRoute.href, location.origin);
	// AUTHENTICATION_URL already contains '?return_uri=' at the end of it.
	// This is why we use string concatenation here instead of new URL.
	const finalUrl = new URL(`${AUTHENTICATION_URL}${returnUri.toString()}`);
	finalUrl.searchParams.append('squareguid', useSquareStore().info.guid);

	window.location.href = finalUrl.toString();
};

export const isSsoDomain = async (email: string): Promise<boolean> => {
	if (!email || email.indexOf('@') < 0) {
		return false;
	}

	return await isSsoDomainApi(email);
};

export const ssoLogin = memoize(async (to?: RouteLocationNormalized, adLogin?: boolean, adMigration?: boolean) => {
	const token = getSsoTokenFromUrl(to);
	if (to?.query?.from_admin === '1' || to?.query?.from_observer === '1') {
		// if we come from admin and already have a token, return it
		if (!adLogin || adMigration) {
			return token;
		}
	}
	try {
		const response = await squareParticipantSso(token);
		if (!response.registeredUser) {
			const responseScreener = response as SsoRedirectToScreener;
			return getScreenerSurveyLink({
				activityGuid: responseScreener.screenerGuid,
				partnerId: responseScreener.partnerId,
				bId: responseScreener.bId,
				firstName: responseScreener.firstName,
				lastName: responseScreener.lastName,
				email: responseScreener.email,
				culture: responseScreener.culture,
				backgroundVariables: [],
			}).then((screenerResponse) => {
				window.open(screenerResponse.link, '_self');
				return Promise.resolve();
			});
		}

		const responseHome = response as SsoRedirectHome;
		// if backend said that we need to activate this member,
		// we return the activation route using member's identity
		if (responseHome.activationRequired && responseHome.identity) {
			// if there is no migration dialog, we go to the activation, otherwise we do nothing
			if (adLogin === true) {
				setShouldActivateAD();
				const activationRoute: RouteLocationNormalized = {
					name: RouteNames.ADActivation,
					query: {
						identity: responseHome.identity,
						token,
					},
					matched: [],
					fullPath: '',
					path: '',
					hash: '',
					params: {},
					meta: {},
					redirectedFrom: undefined,
				};
				return activationRoute;
			}
		}
		return Promise.resolve(responseHome.token);
	} catch {
		return Promise.reject(
			router.push({
				name: RouteNames.LandingPage,
				params: {
					landingTitle: '(SsoLoginFailedTitle)',
					landingMessage: '(SsoLoginFailedMessage)',
					timeout: -1,
				},
			}),
		);
	}
});

export const showLimitedAccessDialog = (isSquare = true, onDismiss?: () => void): void => {
	let firstText = 'Client and its Square(s)';
	let secondText = 'Client';
	if (isSquare) {
		firstText = 'Square';
		secondText = 'Square';
	}

	const dialog = Dialog.create({
		message:
			`The access to this ${firstText} is limited.\n\nPlease contact the project owner - this can be either ` +
			`the CST lead or the PST lead - giving a clear description WHY you need access to WHICH Square(s) and WHO referred you to this ${secondText}.\n\n` +
			'This will make it easier for the project owner to assess your request.\n\nAlternatively, a ticket can be added through the Service Desk.',
	});

	if (onDismiss) {
		dialog.onDismiss(onDismiss);
	}
};

export const hasSsoToken = (to?: RouteLocationNormalized): boolean => !!getSsoTokenFromUrl(to);

export const hasNullSsoToken = (to?: RouteLocationNormalized): boolean =>
	hasSsoToken(to) && getSsoTokenFromUrl(to) === 'null';

export const getSsoTokenFromUrl = (to?: RouteLocationNormalized): string =>
	(to?.query.token || router.currentRoute.value.query.token) as string;

const shouldActivateADKey = 'shouldActivateAD';

export const shouldActivateAD = () => localStorage.getItem(shouldActivateADKey) === '1';
export const setShouldActivateAD = () => localStorage.setItem(shouldActivateADKey, '1');
export const clearShouldActivateAD = () => localStorage.removeItem(shouldActivateADKey);

export const launchAuthenticationService = (
	// we will never use the admin paths anymore, since we have specific user flows in AD
	// also, we will not launch if `admin` is set and `social` is not None
	signup: boolean,
	admin: boolean,
	returnUri: string,
	social: SocialLoginType,
	email?: string,
): boolean => {
	if (admin && social !== SocialLoginType.None) {
		return false;
	}
	const squareStore = useSquareStore();
	const url = new URL(AUTHENTICATION_BASE);
	url.pathname = `${signup ? 'activate' : 'member'}`;
	url.searchParams.append('return_uri', encodeURIComponent(returnUri));

	const specificFlow = social !== SocialLoginType.None ? `-${Object.values(SocialLoginType)[social]}` : '-email';
	const adFlowSuffix = squareStore.info.adFlowSuffix;
	if (adFlowSuffix || specificFlow) {
		// NOTE! for custom flows, the square suffix needs to be the last part
		url.searchParams.append('flow', `${specificFlow}${adFlowSuffix || ''}`);
	}
	const locale = squareStore.info.language;
	if (locale) {
		url.searchParams.append('locale', locale);
	}
	if (email) {
		url.searchParams.append('email', encodeURIComponent(email));
	}
	window.location.href = url.toString();
	return true;
};
