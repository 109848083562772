/**
 * Wait a while for an element to be rendered, and scroll to it afterwards.
 */
export const anchorScrollWithWait = (id: string, waitTime = 5000) => {
	if (findElementAndScroll(id)) {
		return;
	}

	// search for the element every 200ms
	const interval = setInterval(() => {
		if (findElementAndScroll(id)) {
			clearInterval(interval);
		}
	}, 200);
	// the search expires after a while
	setTimeout(() => {
		clearInterval(interval);
	}, waitTime);
};

const findElementAndScroll = (id: string) => {
	const element = document.getElementById(id);
	if (element) {
		try {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		} catch {
			element.scrollIntoView();
		}
		return true;
	}
	return false;
};
