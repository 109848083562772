import { useAuthenticationStore } from '@/store/authentication-store';

const mobileAppParam = 'mobileapp';
let areVideoScriptsLoaded = false;

export const appendMobileAppParam = (url: string): string => {
	if (shouldAppendMobileAppParamTo(url)) {
		return `${url}?${mobileAppParam}`;
	}
	return url;
};

export const loadVideoScripts = (): void => {
	if (!areVideoScriptsLoaded) {
		loadScript('/scripts/youtube.iframe_api.js');
		loadScript('/scripts/azuremediaplayer.min.js');
		areVideoScriptsLoaded = true;
	}
};

export const hasMobileAppParam = (url: string): boolean => url.indexOf(mobileAppParam) !== -1;

const shouldAppendMobileAppParamTo = (url: string): boolean =>
	!!(useAuthenticationStore().isMobileApp && !isExternalUrl(url) && !hasMobileAppParam(url));

const isExternalUrl = (url: string): boolean => url.startsWith('http');

const loadScript = (scriptUrl: string) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	document.getElementsByTagName('head')[0].appendChild(script);
	script.src = scriptUrl;
};
