<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useSquareStore } from '@/store/square-store';
import { getStoreLinksForApp } from '@api/services/query/default/AppService';
import { useConstantsStore } from '@/store/constants-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { Role } from '@/common/constants/enums';

const squareStore = useSquareStore();
const getLabelValue = useConstantsStore().getLabelValue;

const userAgent = computed(() => navigator.userAgent.toLowerCase());
const isAndroid = computed(() => /android/.test(userAgent.value));
const isIos = computed(() => /iphone|ipad|ipod/.test(userAgent.value));

// Example: On your mobile? Download the Human8 Square app from the Google Play store
const playStoreLabel = getLabelValue('MobileAppBannerDownloadAppGooglePlayStore');
const appStoreLabel = getLabelValue('MobileAppBannerDownloadAppAppStore');

const bannerText = ref<string>();

const isMobileBannerClosedKey = 'isMobileBannerClosed';
const isBannerClosed = ref<boolean>(localStorage.getItem(isMobileBannerClosedKey) === 'true');
// Needed when we show the SquareIsNotActive banner
const marginTop = computed(() =>
	!useSquareStore().isActive && useAuthenticationStore().tokenInfo?.role !== Role.Participant ? '105px' : '65px',
);

const closeBanner = () => {
	localStorage.setItem(isMobileBannerClosedKey, 'true');
	isBannerClosed.value = true;
};

onMounted(async () => {
	if (!isBannerClosed.value) {
		const appInfo = await getStoreLinksForApp(squareStore.info.id);
		const storeLink = isAndroid.value ? appInfo?.playStoreLink : isIos.value ? appInfo?.appStoreLink : '';
		const label = isAndroid.value ? playStoreLabel : isIos.value ? appStoreLabel : '';
		bannerText.value = label.replace('Square', `<a href="${storeLink}" target="_blank">Square</a>`);
	}
});
</script>

<template>
	<div
		v-if="!isBannerClosed"
		class="app-banner flex justify-around items-center"
		:style="{ marginTop: marginTop }"
	>
		<!-- eslint-disable next-line vue/no-v-html -->
		<div
			class="app-banner__text"
			v-html="bannerText"
		/>
		<q-btn
			class="app-banner__close-button"
			@click="closeBanner()"
		>
			<i class="fa-solid fa-xmark" />
		</q-btn>
	</div>
</template>

<style lang="scss">
.mobile-header {
	box-shadow: none !important;
}

.app-banner {
	background-color: $app-banner-bg-color;
	color: $dark;
	margin-bottom: -65px; // this is needed so the container itself doesn't get pushed down
	position: relative;
	z-index: 50;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5);

	&__text {
		font-weight: bold;
		width: 70%;
	}

	&__close-button {
		transition: none;
		cursor: pointer;

		&:before {
			box-shadow: none !important;
		}
	}
}
</style>
