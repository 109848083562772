import { useParticipantStore } from '@/store/participant-store';
import { useSquareStore } from '@/store/square-store';
import { h } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { NavigationGuardReturn } from '../../router.types';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';
import { Role } from '@/common/constants/enums';

const ProfilePage = () => import('@/pages/profile-page.vue');

const redirectForSsoSquaresGuard = (): NavigationGuardReturn => {
	const squareStore = useSquareStore();
	if (squareStore.info.ssoEnabled) {
		return {
			name: RouteNames.Home,
			replace: true,
		};
	}
};

export const profileRoute: RouteRecordRaw = {
	name: RouteNames.Profile,
	path: '/profile',
	component: ProfilePage,
	beforeEnter: redirectForSsoSquaresGuard,
	meta: {
		requireLogin: true,
		title: '(LabelWindowTitleProfile)',
	},
	children: [
		{
			name: RouteNames.ProfileActivity,
			path: `activity/:activityGuid(${guidRegex})`,
			component: h(() => ''),
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleProfileActivity)',
				flutterInAppHandler: 'activityClickedInWebview', // indicates that this route might be called from within a webview in the app
			},
		},
		{
			name: RouteNames.ProfileEdit,
			path: 'edit',
			component: h(() => ''),
			beforeEnter: () => {
				const participantStore = useParticipantStore();
				if (participantStore.participant.role !== Role.Participant) {
					return { name: RouteNames.Profile };
				}
			},
			meta: {
				requireLogin: true,
				title: '(LabelWindowTitleEditProfile)',
			},
		},
	],
};
