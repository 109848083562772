<script setup lang="ts">
import { useConstantsStore } from '@/store/constants-store';
import { ref, computed, onMounted } from 'vue';
import { loadCaptchaIfNecessary, getCaptchaResponse } from '@/services/captcha-service';
import { password, dataLoading } from './__composables__/login-composables';
import { useAuthenticationStore } from '@/store/authentication-store';
import { isFeatureEnabledForSquare } from '@/services/feature-service';
import { Feature } from '@/common/constants/enums';
import { useSquareStore } from '@/store/square-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { getSquareCountries } from '@api/services/query/default/SquareService';
import {
	getOpenRegistrationScreenerLink,
	isOpenRegistrationScreenerActiveForSquare,
} from '@api/services/query/default/ActivityService';
import router from '@/router';

defineEmits<(eventname: 'start-admin-sso-login') => void>();
const props = defineProps<{
	invalidFields: boolean;
	email: string;
}>();

const getLabelValue = useConstantsStore().getLabelValue;
const authStore = useAuthenticationStore();
const squareStore = useSquareStore();

const errorMessage = ref('');
const isOpenRegistrationScreenerActive = ref(true);
const isAzureAdB2CEnabled = ref(false);

const isButtonDisabled = computed(() => props.invalidFields || dataLoading.value);

onMounted(async () => {
	isAzureAdB2CEnabled.value = await isFeatureEnabledForSquare(Feature.AzureAdB2CLogin);
	if (squareStore.info.isOpenRegistration) {
		isOpenRegistrationScreenerActive.value = await isOpenRegistrationScreenerActiveForSquare();
	}
});

const login = async () => {
	dataLoading.value = true;
	errorMessage.value = '';
	try {
		const captchaResponse = await getCaptchaResponse('login');
		await authStore.login(props.email, password.value, captchaResponse);
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
	} catch (error: any) {
		errorMessage.value = error?.data?.error || 'Generic message to be translated';
		await loadCaptchaIfNecessary('login');
	} finally {
		dataLoading.value = false;
	}
};

const signUp = async () => {
	if (squareStore.info.countries && squareStore.info.countries.length > 1) {
		await router.push({ name: RouteNames.SignUp });
	} else {
		const countries = await getSquareCountries(squareStore.info.guid);
		// we only have one country here
		const screenerLink = await getOpenRegistrationScreenerLink(squareStore.info.guid, countries[0].segmentGuid);
		window.location.href = screenerLink;
	}
};
</script>

<template>
	<div class="login-submit text-center">
		<q-btn
			:disable="isButtonDisabled"
			unelevated
			:label="getLabelValue('LabelAccountLogin')"
			size="md"
			class="text-bold login-submit__login-button"
			no-caps
			:class="isButtonDisabled ? 'bg-insites-button-disabled text-on-surface-disabled' : 'bg-primary text-white'"
			type="submit"
			@click="login"
		/>
		<div
			v-if="squareStore.info.isOpenRegistration && isOpenRegistrationScreenerActive"
			class="login-submit__open-registration-button-wrapper"
		>
			<q-btn
				unelevated
				color="primary"
				:label="getLabelValue('LabelAccountSignUp')"
				size="md"
				class="text-bold login-submit__open-registration-button q-mt-md"
				no-caps
				@click="signUp"
			/>
		</div>
		<div
			v-if="isAzureAdB2CEnabled"
			class="login-submit__sso-login-button-wrapper"
		>
			<q-btn
				unelevated
				color="primary"
				:label="getLabelValue('LoginSsoButton')"
				size="md"
				class="text-bold login-submit__sso-login-button q-mt-md"
				no-caps
				@click="$emit('start-admin-sso-login')"
			/>
		</div>
		<div
			v-if="errorMessage"
			class="login-submit__error text-caption text-insites-red text-left"
			:class="$q.screen.lt.md ? 'q-mt-lg' : 'q-mt-xl'"
		>
			<span
				v-if="errorMessage === 'User suspended'"
				class="login-submit__suspended"
			>
				{{ getLabelValue('LabelSuspended') }}
			</span>
			<span
				v-else-if="errorMessage === 'User locked'"
				class="login-submit__locked"
			>
				{{ getLabelValue('LabelLocked') }}
			</span>
			<span
				v-else-if="errorMessage === 'Invalid captcha'"
				class="login-submit__invalid-captcha"
			>
				{{ getLabelValue('LabelInvalidCaptcha') }}
			</span>
			<span
				v-else-if="errorMessage !== ''"
				class="login-submit__unauthorized"
			>
				{{ getLabelValue('LabelUnauthorized') }}
			</span>
		</div>
	</div>
</template>
