import { SocialStimulus, NewStimulus } from '@ui/components/post/isc-post.types';
import { StimulusType, StimuliUploadState } from '@ui/common/constants/enums';
import { getLabelValue } from '../label-service';
import { StimulusViewModel } from '@api/models/query';
import { isArray } from 'lodash-es';

export interface SocialStimulusExtended extends SocialStimulus {
	getUrl: (url: string) => string;
}

const socialStimulusTypes: SocialStimulusExtended[] = [
	{
		type: StimulusType.Youtube,
		pattern: /(?:youtube\.com.+v=|youtu\.be\/)([a-z0-9_-]+)/i,
		getThumbnail: async (videoId) => `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
		getUrl: (videoId: string) => composeVideoUrlForYoutube(videoId),
	},
	{
		type: StimulusType.Vimeo,
		pattern: /vimeo.com\/(?:[a-z]*\/)*([0-9]{6,11})/,
		getThumbnail: async (videoId) => await getVimeoThumbnail(videoId),
		getUrl: (videoId: string) => composeVideoUrlForVimeo(videoId),
	},
];

const getVimeoThumbnail = async (videoId: string) => {
	const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
	const responseBody = await response.json();

	if (responseBody && isArray(responseBody) && responseBody.length > 0 && responseBody[0]?.thumbnail_medium) {
		return responseBody[0]?.thumbnail_medium as string;
	}

	return `https://i.vimeocdn.com/video/${videoId}`;
};

export const getSocialStimulusFromUrl = async (url: string): Promise<NewStimulus | null> => {
	if (url && socialStimulusTypes.length > 0) {
		for (const stimulusType of socialStimulusTypes) {
			const match = url.match(stimulusType.pattern);
			if (match) {
				const videoId = match[1];
				return {
					type: stimulusType.type,
					url: stimulusType.getUrl(videoId),
					thumbnailUrl: await stimulusType.getThumbnail(videoId),
					socialStimulusId: videoId,
					guid: '',
					key: '',
					sortOrder: 0,
					status: StimuliUploadState.Empty,
				};
			}
		}
		// if the stimulus object wasn't returned already, it means there was no matching pattern,
		// so the format of the provided URL is not accepted
		const alertMessage = getLabelValue('LabelLinkAlert', 'Link format not supported!');
		alert(alertMessage);
	}
	return null;
};

const composeVideoUrlForVimeo = (videoId: string): string => `https://vimeo.com/${videoId}`;
const composeVideoUrlForYoutube = (videoId: string): string => `https://www.youtube.com/embed/${videoId}?rel=0`;

export const isSocialStimulus = (stimulus: StimulusViewModel): boolean =>
	stimulus.type === StimulusType.Vimeo || stimulus.type === StimulusType.Youtube;
