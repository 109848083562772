import { hasNullSsoToken, hasSsoToken, getSsoTokenFromUrl } from '@/common/utils/sso';

interface SsoCheckService {
	hasSsoToken: () => boolean;
	hasNullSsoToken: () => boolean;
	getSsoToken: () => string;
}

export default {
	hasSsoToken,
	hasNullSsoToken,
	getSsoToken: getSsoTokenFromUrl,
} as SsoCheckService;
