import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const PageNotFound = () => import('@/pages/not-found-page.vue');

export const notFoundRoute: RouteRecordRaw = {
	name: RouteNames.NotFound,
	path: '/:pathMatch(.*)*',
	component: PageNotFound,
	meta: {
		title: 'Page Not Found',
		requireLogin: false,
	},
};
