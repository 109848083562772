import { AuthorViewModel } from '@api/models/query';
import { Role } from '@ui/common/constants/enums';
import { computed, ref } from 'vue';

type CurrentUser = Omit<AuthorViewModel, 'id'> & {
	id?: number;
	isFullDev?: boolean;
};

const currentUser = ref<CurrentUser>();

export const setCurrentUser = (user: CurrentUser) => {
	currentUser.value = user;
};

export const useCurrentUser = () => {
	/**
	 * Checks if the current user has the Human8 or a Moderator role
	 */
	const isAdmin = computed(
		() =>
			currentUser.value?.role === Role.Human8 ||
			currentUser.value?.role === Role.ProfessionalAdmin ||
			currentUser.value?.role === Role.ClientAdmin ||
			currentUser.value?.role === Role.ClientEditor,
	);

	/**
	 * Checks if the current user has the Participant role
	 */
	const isParticipant = computed(() => currentUser.value?.role === Role.Participant);

	/**
	 * Checks if the current user has the Observer role
	 */
	const isObserver = computed(() => currentUser.value?.role === Role.Observer);

	/**
	 * Checks if the current user has the Human8, Moderator or Observer role (these roles are able to access the management module)
	 */
	const isManagementModuleRole = computed(() => isAdmin.value || isObserver.value);

	return {
		currentUser,
		isAdmin,
		isParticipant,
		isObserver,
		isManagementModuleRole,
	};
};
