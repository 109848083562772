import { computed } from 'vue';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useRoute } from 'vue-router';

export const useSelectedTab = () => {
	const route = useRoute();

	const selectedTab = computed({
		get: () => {
			const routeName = route.name?.toString();
			if (routeName?.includes(RouteNames.Home)) {
				return RouteNames.Home;
			}
			if (routeName?.includes(RouteNames.Challenges)) {
				return RouteNames.Challenges;
			}
			if (routeName?.includes(RouteNames.Forum)) {
				return `${RouteNames.Forum}-${route.params.pageGuid}`;
			}
			if (routeName?.includes(RouteNames.News)) {
				return `${RouteNames.News}-${route.params.pageGuid}`;
			}
			if (routeName?.includes(RouteNames.MainpageCustom)) {
				return `${RouteNames.MainpageCustom}-${route.params.pageGuid}`;
			}

			return routeName;
		},
		set: () => {
			// Do nothing, this will be handled by the router-link in the template
		},
	});

	return {
		selectedTab,
	};
};
