import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const ResetPasswordComponent = () => import('@/pages/reset-password-page.vue');

export const resetPasswordRoute: RouteRecordRaw = {
	name: RouteNames.ResetPassword,
	path: '/resetpassword',
	component: ResetPasswordComponent,
	meta: {
		requireLogin: false,
		title: '(LabelWindowTitleForgotPassword)',
	},
};
