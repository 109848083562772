import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const signinErrorPage = () => import('@/pages/signin-error-page.vue');

export const signInErrorRoute: RouteRecordRaw = {
	name: RouteNames.SignInError,
	path: '/signinerror',
	component: signinErrorPage,
	meta: {
		title: '',
		requireLogin: false,
		hideFooter: true,
	},
};
