import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';
import { usePagesStore } from '@/store/pages-store';

const CustomPage = () => import('@/pages/custom-page.vue');

export const mainpageCustomRoute: RouteRecordRaw = {
	name: RouteNames.MainpageCustom,
	path: `/custom/:pageGuid(${guidRegex})`,
	component: CustomPage,
	meta: {
		requireLogin: true,
		title: (routeParams: RouteParams) => {
			const pagesStore = usePagesStore();
			return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleCustom)';
		},
	},
};
