import { getCurrentSubdomain } from '@/common/utils/url';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	if (!to.name || !(to.name as string).startsWith('container')) {
		return;
	}

	if (to.name === RouteNames.NotFound && to.query.noSquareInfo) {
		return;
	}

	if (!getCurrentSubdomain()) {
		return { name: RouteNames.NotFound, query: { noSquareInfo: 'true' } };
	}
};
