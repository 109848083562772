import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

export const loginErrorRoute: RouteRecordRaw = {
	name: RouteNames.LoginError,
	path: '/loginerror',
	component: getWrappedAngularComponent('loginerror'),
	meta: {
		requireLogin: false,
		title: '(LoginErrorTitle)',
	},
};
