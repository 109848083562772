/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSquareStore } from '@/store/square-store';
import { SquareDesignDetailItemResponse } from '@api/models/query';
import angular from 'angular';
// @ts-expect-error: No types
import tinycolor from 'angular-square/node_modules/tinycolor2';
import { forEach, range } from 'lodash-es';

const setCustomStyles = (injector: angular.auto.IInjectorService) => {
	const design = useSquareStore().design;
	const $mdTheming = injector.get('$mdTheming');
	const $mdThemingProvider = injector.get('mdThemingProvider');
	const pageThemes = injector.get('pageThemes');
	updateMaterialThemes(design, $mdTheming, $mdThemingProvider, pageThemes);
};

const updateMaterialThemes = (
	design: SquareDesignDetailItemResponse,
	$mdTheming: any,
	$mdThemingProvider: any,
	pageThemes: any,
) => {
	design.pageDesign?.forEach((pageDesign, index) => {
		const themeName = `pageTheme${index}`;
		const paletteName = `pagePalette${index}`;
		generatePalette($mdTheming, $mdThemingProvider, themeName, paletteName, pageDesign.color || '');
		pageThemes.addPageTheme(pageDesign.pageGuid, themeName);
	});

	generatePalette($mdTheming, $mdThemingProvider, 'default', 'defaultPalette', design.squareDesign?.baseColor1 || '');
	generatePalette(
		$mdTheming,
		$mdThemingProvider,
		'secondary',
		'secondaryPalette',
		design.squareDesign?.baseColor1 || '',
	);
	generatePalette($mdTheming, $mdThemingProvider, pageThemes.defaultGreyTheme, 'greyPalette', '#888888');

	$mdTheming.generateTheme('default');
	$mdTheming.generateTheme('secondary');
	$mdTheming.generateTheme(pageThemes.homePageTheme);
	$mdTheming.generateTheme(pageThemes.defaultGreyTheme);
};

const generatePalette = (
	$mdTheming: any,
	$mdThemingProvider: any,
	themeName: string,
	paletteName: string,
	baseColor: string,
) => {
	const color = tinycolor(baseColor);
	const paletteMap = extendPalette($mdTheming, 'grey', {
		50: color.clone().lighten(25).toHexString(),
		100: color.clone().lighten(20).toHexString(),
		200: color.clone().lighten(15).toHexString(),
		300: color.clone().lighten(10).toHexString(),
		400: color.clone().lighten(5).toHexString(),
		500: baseColor,
		600: color.clone().darken(5).toHexString(),
		700: color.clone().darken(10).toHexString(),
		800: color.clone().darken(15).toHexString(),
		900: color.clone().darken(20).toHexString(),
		A100: color.clone().lighten(10).toHexString(),
		A200: baseColor,
		A400: color.clone().darken(6).toHexString(),
		A700: color.clone().darken(12).toHexString(),
	});
	$mdTheming.PALETTES[paletteName] = paletteMap;
	$mdThemingProvider._PALETTES[paletteName] = paletteMap;
	$mdThemingProvider.theme(themeName).primaryPalette(paletteName).accentPalette(paletteName);
};

const extendPalette = ($mdTheming: any, name: string, object: Record<number | string, any>) => {
	const newPalette = angular.copy($mdTheming.PALETTES[name]);
	forEach(object, (value, key) => {
		const color = tinycolor(value);
		const rgb = color.toRgb();
		newPalette[key].hex = value;
		newPalette[key].value = [rgb.r, rgb.g, rgb.b];
		if (color.darken(3).isDark()) {
			newPalette[key].contrast = [255, 255, 255, 1];
		} else {
			newPalette[key].contrast = [0, 0, 0, 1];
		}
	});
	return newPalette;
};

customStylesConfig.$inject = ['$mdThemingProvider', '$provide'];
function customStylesConfig($mdThemingProvider: any, $provide: any) {
	$mdThemingProvider.generateThemesOnDemand(true);
	$mdThemingProvider.alwaysWatchTheme(true);
	forEach(range(0, 10), (index) => {
		$mdThemingProvider.theme(`pageTheme${index}`);
	});
	$mdThemingProvider.theme('secondary');
	$provide.value('mdThemingProvider', $mdThemingProvider);
}

export { setCustomStyles, customStylesConfig };
