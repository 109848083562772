<template>
	<div>
		<div
			v-pre
			id="maincontent"
			ref="component"
		/>
	</div>
	<router-view v-if="!shouldShowMobileAppPage" />
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, Ref, ref, computed } from 'vue';
import angular from 'angular';
import { kebabCase } from 'lodash-es';
import { setCustomStyles } from './custom-styles';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { RouteNames } from '../../router/routes/route-names.enum';
import { useAuthenticationStore } from '../../store/authentication-store';

const props = defineProps<{ name: string }>();
const component: Ref<HTMLElement | null> = ref(null);
const router = useRouter();
const shouldShowMobileAppPage = computed(() => useAuthenticationStore().shouldShowMobileAppPage);

const initComponent = async () => {
	// We can't do anything if the component is not defined.
	if (!component.value) {
		return;
	}
	const ang = angular.element(component.value);
	if (!ang.injector()) {
		angular.bootstrap(ang, ['insitesApp']);
	}

	const injector = ang.injector();
	// Custom styles for angular
	setCustomStyles(injector);

	const componentName = kebabCase(props.name);
	// Include the angular component. In addition to that, we also need to include the task-list angular component,
	// which displays the toast notifications in the top right. It used to be displayed in the main container of the angular implementation
	ang.html(`
    <${componentName}></${componentName}> 
    <task-list> </task-list> 
  `);

	injector.invoke([
		'$compile',
		function ($compile) {
			$compile(ang)(ang.scope());
		},
	]);
};

const destroyComponent = () => {
	const children = component.value?.children;
	if (!children) {
		return;
	}
	const arr = Array.from(children);
	for (const elem of arr) {
		angular.element(elem).scope().$broadcast('$destroy');
	}
};

onMounted(async () => await initComponent());

onBeforeUnmount(() => {
	destroyComponent();
});

onBeforeRouteLeave((to, from) => {
	if (
		to.name === RouteNames.Home &&
		from.name === RouteNames.Login &&
		from.redirectedFrom &&
		from.redirectedFrom?.name !== RouteNames.Main
	) {
		const name = from.redirectedFrom?.name?.toString();
		const params = from.redirectedFrom?.params;
		router.push({ name, params });
	}
});
</script>

<style lang="scss">
@import 'angular-square/dist/styles/site';
/* stylelint-disable selector-max-id */
[id='maincontent'] {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: revert;
		font-weight: revert;
		line-height: revert;
		letter-spacing: revert;
	}

	.row,
	.column,
	.flex {
		flex-wrap: unset;
	}
	.layout-row,
	.layout-column {
		display: flex;
	}
	p {
		margin: 1em 0;
	}
	.content p {
		margin: 20px 0;
	}
	.disabled,
	[disabled] {
		opacity: unset !important; // important is needed here because Quasar also uses important
	}
	.disabled,
	.disabled *,
	[disabled],
	[disabled] * {
		cursor: auto !important; // important is needed here because Quasar also uses important
	}
	h3 {
		line-height: 1rem;
	}
	h4 {
		font-size: unset;
		font-weight: bold;
	}
	.ui-view > * {
		width: 100%;
	}

	.show-replies-button-content button {
		text-align: center;
	}

	/* This is necessary because of some conflicts with flex classes */
	.conversation-add-stimuli button {
		font-size: 0.875rem;
		display: block;

		// Quasar automatically adds opacity to disabled elements while that isn't the case in Angular Material
		// To make sure Quasar styling didn't leak into our Angular component, that behaviour is overriden above using important
		// For these buttons, though, we need the following styling, that's why we also need important here
		&:disabled {
			opacity: 0.3 !important;
			cursor: no-drop !important;

			* {
				cursor: no-drop !important;
			}
		}
	}
	/* The two bellow are necessary because the structure of the html in Angular is different than in Vue, so they need to be specified here again */
	.submit-answer-button {
		margin: margin-inline-values(20px 0 20px 0);
	}
	.divided-qual-banner {
		position: relative;
		background-color: $white;
		color: $notification-background;
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		border: 1px solid $light-grey;
		border-radius: border-inline-radius-values(2px);
		align-items: center;
		padding: padding-inline-values(6px 30px);
		@include media(gt-sm) {
			box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.5);
			border: 0;
		}
	}
	// this one is necessary because on some pages we still use the angular footer and the css should be applied in those cases
	isc-footer {
		display: none;
	}

	qual-activity-post {
		isc-forum-post {
			max-width: inherit;
		}
	}

	md-content {
		display: block;
		position: relative;
		overflow: auto;
	}

	md-content.intro p {
		margin: 0;
	}

	/* Because of css coming from Quasar ([aria-disabled] { cursor: default; }), the ng-wig would show the default cursor. */
	md-input-container:not([disabled]) ng-wig:not([disabled]) #ng-wig-editable:not([disabled]) {
		cursor: text;
	}

	task-list {
		.toast {
			margin-top: 0;
		}
	}
}
/* stylelint-enable selector-max-id */
</style>
