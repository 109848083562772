import { RouteNames } from '../routes/route-names.enum';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { DateTime } from 'luxon';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { useParticipantStore } from '@/store/participant-store';
import { Dialog } from 'quasar';
import AdMigrationPage from '@/pages/ad-migration-page.vue';
import { UpdateSquareParticipantDetailRequest } from '@api/models/command';
import { updateSquareParticipantDetails } from '@api/services/command/default/ParticipantService';
import { computed } from 'vue';
import { Role } from '@/common/constants/enums';

//
// dialog visibility data
//
const sessionKey = computed(() => `${useParticipantStore().participant.guid}_mdv`);
// is migration dialog visible or not
const isMigrationDialogVisible = () => sessionStorage.getItem(sessionKey.value) !== null;
// dialog closed, should be triggered only from the dialog itself
export const setMigrationDialogClosed = () => sessionStorage.removeItem(sessionKey.value);
// dialog is visible, should be triggered only from the dialog itself
export const setMigrationDialogVisible = () => sessionStorage.setItem(sessionKey.value, '1');
// should be triggered only by the 2 routes from the dialog (updateaccount and emailmigration)
export const hideMigrationDialog = () => {
	if (isMigrationDialogVisible()) {
		if (hideCallback) {
			hideCallback();
		}
		// failsafe
		sessionStorage.removeItem(sessionKey.value);
	}
};
let hideCallback: () => void = () => {};
export const setHideMigrationCallback = (callback: () => void) => {
	hideCallback = callback;
};

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	/*
    this guard is responsible for showing the migration dialog for AD login enabled squares
    it should NOT be in effect when
      - there is no user logged in
      - the logged in user already has an AD object ID
      - square is not AD login enabled
      - square has AD login enabled but does not have AD migration dialog toggle enabled
      - coming from the AD landing page directly (fron the login)
      - to route is one of the AD pages involved in migration
        - ADActivation - account activation questions and communication email,
        - ADUpdateAccount - square participant selected `update my account` in case
          - they did not have any other migration yet whatsoever or
          - to update or create a new AD account for migration at a later stage,
        - ADEmailMigration - when square participant wants to create an AD account using their initial email in square
        - LastStep - the migration last step
      - to route is allowed on mobile web

    what does it do?
      - if it passes validations from above, it will check participant.dateMigrationModalShown
        - if it does not have a value, it means that participant is logging in for the first time after square switched to AD
          - so, it will just start countdown to 24 hours
        -otherwise, will check if 24 hours passed since participant.dateMigrationModalShown
          - if not, nothing to do
          - otherwise, show the dialog for migration

    note!
      - migration dialog is allowed to be dismissed once
        - will update timer when dismissed
      - once migration dialog is dismissed by user and another 24 hours pass
        - dismissing it will be disabled
        - refreshing MP should still show it
        - navigating from browser should still show it
  */

	const authStore = useAuthenticationStore();
	const squareStore = useSquareStore();

	if (!authStore.isAuthorized || authStore.isAdUser || !squareStore.info.adLogin || !squareStore.info.adMigration) {
		// we do not care about anything if nobody is authorized or there is an AD user logged in
		// we also do not care if there is no AD for square or migration is disabled
		return true;
	}

	const allowedPagesOnMobileWebApp = [
		RouteNames.Unsubscribe,
		RouteNames.Reactivate,
		RouteNames.LandingPage,
		RouteNames.Registration,
	];

	if (from.name === RouteNames.AdSignIn) {
		// we come straight from login
		return true;
	}

	if (
		to.name === RouteNames.SSO ||
		to.name === RouteNames.ADUpdateAccount ||
		to.name === RouteNames.ADEmailMigration ||
		to.name === RouteNames.ADActivation ||
		to.name === RouteNames.LastStep
	) {
		// we are going to AD migration routes
		return true;
	}

	if (allowedPagesOnMobileWebApp.includes(to.name as RouteNames)) {
		return true;
	}

	showMigrationForCurrentParticipant(squareStore.info.adMigrationForced);
	return true;
};

const showMigrationForCurrentParticipant = (forced: boolean) => {
	const participantStore = useParticipantStore();
	// for human8, do not do anything
	if (participantStore.participant.role === Role.Human8) {
		return true;
	}
	let result = false;
	if (!participantStore.participant.dateMigrationModalShown) {
		setTimeout(async () => {
			// first time we do not show the page, we only update participant
			// we use a 5 seconds delay so that home page would be properly loaded
			const updateRequest: UpdateSquareParticipantDetailRequest = {
				squareParticipantGuid: participantStore.participant.guid,
				username: participantStore.participant.username,
				userType: participantStore.participant.role,
				email: participantStore.participant.email,
				firstName: participantStore.participant.firstName,
				lastName: participantStore.participant.lastName,
				communicationEmail: participantStore.participant.communicationEmail,
				dateMigrationModalShown: DateTime.utc(),
				isBasicInfoChanged: false,
			};
			participantStore.setDateMigrationDialogShown(DateTime.utc());
			await updateSquareParticipantDetails(updateRequest);
		}, 5000);
	} else {
		// if we force its persistence and it was dismissed once, we show dialog
		if (forced && participantStore.participant.migrationDismissed) {
			result = true;
		} else {
			const dateShown = DateTime.fromISO(participantStore.participant.dateMigrationModalShown.toString());
			result = DateTime.utc().diff(dateShown, 'hours').hours > 24;
		}
	}

	if (result) {
		showMigrationDialog();
	}
};

export const showMigrationDialog = (): boolean => {
	if (isMigrationDialogVisible()) {
		return false;
	}

	setTimeout(
		() =>
			Dialog.create({
				component: AdMigrationPage,
			}),
		1000,
	);
	return true;
};
