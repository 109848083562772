// The cookie of cookieYes looks like this:
// cookieyes-consent=consentid:consentid,consent:no,action:,necessary:yes,functional:no,analytics:no,performance:no,advertisement:no'
export const GetCookieConsent = (): Partial<CookieConsent> => {
	const cookies = document.cookie;

	if (!cookies) {
		return {};
	}

	const splittedCookies = cookies.split(';').map((cookie) => cookie.trim().split('='));
	const cookieDict = Object.fromEntries(splittedCookies);

	const cookieYes = cookieDict['cookieyes-consent'];

	// If the cookie doesn't exist, return an empty object.
	if (!cookieYes) {
		return {};
	}

	const cookieConsent: Partial<CookieConsent> = {};
	type CookieConsentKey = keyof CookieConsent;

	// Split the 'cookieyes-consent' value into parts
	const parts = cookieYes.split(',');
	for (const part of parts) {
		const [key, value] = part.split(':') as [string, string];
		cookieConsent[key as CookieConsentKey] = value;
	}

	return cookieConsent;
};

export interface CookieConsent {
	consentid: string;
	consent: string;
	action: string;
	necessary: string;
	functional: string;
	analytics: string;
	performance: string;
	advertisement: string;
}
