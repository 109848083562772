import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BUILD_VERSION, QUERY_API_URL, COMMAND_API_URL, MEMBER_API_URL } from '@/common/env.config';
import { Impersonate } from '@/store/authentication-store.types';
import { convertPascalCasedObjectToCamelCasedObject } from '@ui/common/utils/convert-case';
import { convertDateTimesForObject } from '@ui/common/utils/convert-luxon';
import { configureApi } from '@api/config';
import { Notify } from 'quasar';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import { hasSsoToken } from '@/common/utils/sso';
import { useConstantsStore } from '@/store/constants-store';
import { combineURLPaths } from '@ui/common/utils/url';
import { useSquareStore } from '@/store/square-store';
import qs from 'qs';

axios.interceptors.request.use((config) => {
	// Add headers to each request
	config.headers.set('BuildVersion', BUILD_VERSION);
	config.headers.set('ClientGuid', useSquareStore().clientGuid);
	config.headers.set('SquareGuid', useSquareStore().squareGuid);

	// Configure paramsSerializer so that arrays are serialized correctly
	config.paramsSerializer = {
		serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	};
	// .Net expects the key to be present in the query string, even for nullable values.
	// query-string automatically removes all undefined variables from the query string.
	// We manually change every undefined and null to '' since query-string does keep those.
	if (config.params && typeof config.params === 'object') {
		Object.entries(config.params).forEach(([key, value]) => {
			if (value === undefined || value === null) {
				config.params[key] = '';
			}
		});
	}

	return config;
});

// Make sure responses are camelCased
axios.interceptors.response.use(
	(response) => {
		if (response?.data && response?.headers['content-type']?.includes('application/json')) {
			response.data = convertPascalCasedObjectToCamelCasedObject(response.data);
			response.data = convertDateTimesForObject(response.data);
		}

		return response;
	},
	(err: AxiosError<{ Errors?: string[]; Message?: string } & unknown>) => {
		const { response, request, code } = err;
		if (!response) {
			// If we cancelled the request ourselves, don't show the something went wrong notification.
			// We still throw an exception
			if (code !== 'ERR_CANCELED') {
				Notify.create({
					message: 'Something went wrong',
					type: 'negative',
				});
			}
			// eslint-disable-next-line no-console
			console.error('The following request did not receive a response:', request);
			return Promise.reject(err);
		}
		const camelCasedResponse = convertPascalCasedObjectToCamelCasedObject(response);
		if (camelCasedResponse.status === 401) {
			if (!useAuthenticationStore().tokenInfo?.isMobileAppUser && !hasSsoToken()) {
				return useAuthenticationStore().signout();
			}
		}

		if (camelCasedResponse.status === 500) {
			// Errors should only be filled in when running the backend in development mode.
			// So, it's okay to show the errors in the console and no translation is needed.
			if (camelCasedResponse.data.errors) {
				Notify.create({
					message: 'Something went wrong server side, check the console for the Stacktrace',
					type: 'negative',
				});
				for (const error of camelCasedResponse.data.errors) {
					// eslint-disable-next-line no-console
					console.error(error);
				}
			} else {
				// Message should only be filled in when running the backend in development mode.
				// So, it's okay to show them directly.
				const message =
					camelCasedResponse.data.message ||
					useConstantsStore().getLabelValue('LabelSomethingWentWrong') ||
					'Something went wrong';
				Notify.create({
					message,
					type: 'negative',
				});
				// eslint-disable-next-line no-console
				console.error(message);
			}
		}

		// The client is in migration
		if (camelCasedResponse.status === 503) {
			if (router.currentRoute.value.name !== RouteNames.Maintenance) {
				router.push({
					name: RouteNames.Maintenance,
					query: { goto: document.location.href },
				});
			}
		}

		return Promise.reject(camelCasedResponse);
	},
);

type Config = Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>;

export const get = async <T = unknown>(
	url: string,
	params?: Record<string, unknown>,
	config?: Config,
	baseUrl = QUERY_API_URL,
): Promise<T> => {
	const res = await axios.get<T>(combineURLPaths(config?.baseURL ?? baseUrl, url), {
		...config,
		params,
	});
	return res.data;
};

export const post = async <T = unknown>(
	url: string,
	data?: unknown,
	params?: Record<string, unknown>,
	config?: Config,
	baseUrl = COMMAND_API_URL,
): Promise<T> => {
	const res = await axios.post<T>(combineURLPaths(config?.baseURL ?? baseUrl, url), data, {
		...config,
		params,
	});

	return res.data;
};

export const upload = async <T = unknown>(
	url: string,
	data: Record<string, string | Blob>,
	params?: Record<string, unknown>,
	config?: Config,
	baseUrl = COMMAND_API_URL,
): Promise<T> => {
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		formData.append(key, value);
	});

	const extendedConfig: typeof config = {
		...config,
		headers: {
			...config?.headers,
			'Content-Type': 'multipart/form-data',
		},
	};
	return post<T>(url, data, params, extendedConfig, baseUrl);
};

export const put = async <T = unknown>(
	url: string,
	data?: unknown,
	params?: Record<string, unknown>,
	config?: Config,
	baseUrl = COMMAND_API_URL,
): Promise<T> => {
	const res = await axios.put<T>(combineURLPaths(config?.baseURL ?? baseUrl, url), data, {
		...config,
		params,
	});

	return res.data;
};

export const useImpersonate = () => {
	axios.interceptors.request.use((config) => {
		const impersonate: Impersonate | null = useAuthenticationStore().impersonate;
		if (impersonate?.squareParticipantGuid) {
			config.headers.set('ImpersonateGuid', impersonate?.squareParticipantGuid);
		}

		return config;
	});
};

export const useToken = (jwt: string) => {
	axios.interceptors.request.use((config) => {
		config.headers.set('Authorization', `Bearer ${jwt}`);
		return config;
	});
};

configureApi(
	{
		get,
		post,
		put,
	},
	QUERY_API_URL,
	COMMAND_API_URL,
	MEMBER_API_URL,
);
