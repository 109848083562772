import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';

const AdOpenRegistrationPage = () => import('@/pages/ad-signin-page.vue');

export const AdSignInRoute: RouteRecordRaw = {
	name: RouteNames.AdSignIn,
	path: '/signin',
	component: AdOpenRegistrationPage,
	meta: {
		title: '',
		requireLogin: false,
		hideFooter: false,
	},
	beforeEnter: async () => {
		const squareStore = useSquareStore();
		// If already logged in or the square is not ad go to home
		if (useAuthenticationStore().isAuthorized || !squareStore.info.adLogin) {
			return { name: RouteNames.Home };
		}
		return true;
	},
};
