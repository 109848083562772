<template>
	<q-dialog
		ref="dialogRef"
		no-route-dismiss
		class="isc-sequencemodal"
		@hide="onDialogHide"
	>
		<isc-wrapper-page class="q-px-xl">
			<h2 class="isc-sequencemodal__completed-dialog-title text-weight-bold text-primary text-center text-h5">
				{{ title }}
			</h2>
			<!-- eslint-disable vue/no-v-html -->
			<div
				class="isc-sequencemodal__completed-dialog-message"
				v-html="messageLabel"
			/>
			<!-- eslint-enable vue/no-v-html -->

			<div class="isc-sequencemodal__completed-dialog-control q-pa-lg row justify-center q-gutter-sm">
				<q-btn
					v-close-popup
					flat
					no-caps
					class="isc-sequencemodal__cancel text-bold"
					:label="getLabelValue('LabelSequencedActivityCompletionModalCancel', 'Cancel')"
					@click="onCancel"
				/>
				<q-btn
					v-close-popup
					class="isc-sequencemodal__ok text-bold"
					color="primary"
					unelevated
					no-caps
					:label="confirmLabel"
					@click="onOk"
				/>
			</div>
		</isc-wrapper-page>
	</q-dialog>
</template>

<script lang="ts" setup>
import { setCssVar, useDialogPluginComponent } from 'quasar';
import { NextSequencedActivityRedirectInfoResponse } from '@api/models/query';
import { getLabelValue } from '@ui/services/label-service';
import { IscWrapperPage } from '@ui/components';
import { onMounted } from 'vue';

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();

export interface Props {
	info: NextSequencedActivityRedirectInfoResponse;
	homeLabel: string;
	color?: string;
}

const props = defineProps<Props>();

defineEmits([...useDialogPluginComponent.emits]);

const messageReplacements = {
	completedactivityname: props.info.completedActivityName ?? '',
	completedactivityorder: props.info.completedActivityOrder.toString(),
	totalactivitiesinsequence: props.info.totalActivitiesInSequence.toString(),
	nextactivityname: props.info.nextActivityName ?? '',
	nextactivityorder: props.info.nextActivityOrder.toString(),
	home: props.homeLabel,
};

const confirmLabel = props.info.nextActivityGuid
	? getLabelValue('LabelTakeMeToNextSequencedActivityButton', 'Take me to the next Activity')
	: getLabelValue('LabelTakeMeToHomePageButton', 'Take me to {home}', {
			home: props.homeLabel,
		});
const onOk = () => onDialogOK();

const onCancel = () => onDialogCancel();

const title = getLabelValue('LabelSequencedActivityCompletionModalTitle', 'Congratulations!');

const messageLabel = props.info.nextActivityGuid
	? getLabelValue(
			'LabelSequencedActivityCompletionModalBody',
			`Congratulations, you completed '&lt;b&gt;{completedactivityname}&lt;/b&gt; ({completedactivityorder}/{totalactivitiesinsequence})'
  and you unlocked a new Activity (&lt;b&gt;{nextactivityname}&lt;/b&gt;).&lt;br/&gt;Click below to proceed to the next Activity in the chain
  ({nextactivityorder}/{totalactivitiesinsequence})!`,
			messageReplacements,
		)
	: getLabelValue(
			'LabelLastSequencedActivityCompletionModalBody',
			`Congratulations, you completed '&lt;b&gt;{completedactivityname}&lt;/b&gt; ({completedactivityorder}/{totalactivitiesinsequence})'.
  You completed all Activities in this chain.&lt;br/&gt;Click below to proceed to {home}.`,
			messageReplacements,
		);

onMounted(() => {
	if (props.color) {
		setCssVar('primary', props.color, dialogRef.value?.$el);
	}
});
</script>
