import { validationConstants } from '@/common/constants/constants';
import { Area, PageType, Feature } from '@/common/constants/enums';
import router from '@/router';
import { listPageConsumer, getFaqPageDetails } from '@api/services/query/default/PageService';
import { isFeatureEnabledForSquare } from '@/services/feature-service';
import { groupBy, memoize } from 'lodash-es';
import { defineStore } from 'pinia';
import { useAuthenticationStore } from './authentication-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { find, forEach } from 'lodash-es';
import { FaqPageDetailItemResponse } from '@api/models/query';
import { allowedToNavigateToPage as allowedToNavigateToPageApi } from '@api/services/query/default/PageService';

export interface PageConsumer {
	id: number;
	guid: string;
	name?: string;
	intro?: string;
	icon?: string;
	pageType: PageType;
	area: Area;
	openInNewTab?: boolean;
	customUrl?: string;
	editUrl?: string;
	isAboutThisProjectPage: boolean;
	link?: string;
	target?: string;
	image?: string;
	route?: string;
	routeParams?: Record<string, string>;
}

export const usePagesStore = defineStore('pages', {
	state: () => ({
		pages: [] as PageConsumer[],
		faqPageDetails: {} as FaqPageDetailItemResponse,
	}),

	actions: {
		async init() {
			const authStore = useAuthenticationStore();
			// If the participant isn't logged in yet, we want to refetch the pages on login,
			// since a logged in participant will receive more pages.
			if (!authStore.isAuthorized) {
				authStore.loginFunctions.push(async () => {
					await this.fetchConsumerPages();
				});
			} else {
				await this.fetchConsumerPages();
			}
		},
		async fetchConsumerPages(isTriggeredBySignalR = false) {
			const response = await listPageConsumer();
			const isAuthorized = useAuthenticationStore().isAuthorized;
			// If there is a signal R event that changed the pages we want to call the endpoint again and dont use caching
			const isSavingUpEnabled = isAuthorized
				? await isFeatureEnabledForSquare(Feature.SavingUpRewards, isTriggeredBySignalR)
				: false;
			syncPageResponse(this.$state, response.list || [], isSavingUpEnabled);
			if (isAuthorized) {
				await this.fetchFaqPageDetails();
			}
		},
		goToPage(page: PageConsumer, replace: boolean) {
			const location = {
				name: page.route,
				params: page.routeParams,
			};

			if (replace) {
				router.replace(location);
			} else {
				router.push(location);
			}
		},
		async fetchFaqPageDetails() {
			const helpCenterFaqPage = this.helpCenterPages.filter((page) => page.pageType === PageType.FAQ)[0];
			if (helpCenterFaqPage) {
				this.faqPageDetails = await getFaqPageDetails(true, helpCenterFaqPage.guid);
				const aboutThisProjectPage: PageConsumer | undefined = find<PageConsumer>(
					this.mainPages,
					(item) => item.isAboutThisProjectPage,
				);
				const aboutThisProjectUrl = aboutThisProjectPage
					? router.resolve({
							name: RouteNames.News,
							params: {
								pageGuid: aboutThisProjectPage.guid,
							},
						}).href
					: '/';
				const myRewardsUrl = router.resolve({
					name: RouteNames.IncentivesMyIncentives,
				}).href;

				forEach(this.faqPageDetails.questions, (question) => {
					question.answer = question.answer
						?.replaceAll('{aboutthisprojectpage}', aboutThisProjectUrl)
						.replaceAll('{myrewardspage}', myRewardsUrl);
				});
			}
		},
		allowedToNavigateToPage: memoize(async (pageGuid: string) => {
			try {
				return await allowedToNavigateToPageApi(pageGuid);
			} catch {
				// If an error occurs, it probably means we're trying to navigate to a page that doesn't exist
				// and thus should treat it like we don't have access
				return false;
			}
		}),
	},

	getters: {
		mainPages: (state) => state.pages.filter((page) => page.area === Area.MainPages),
		helpCenterPages: (state) => state.pages.filter((page) => page.area === Area.HelpCenter),
		incentivePages: (state) => state.pages.filter((page) => page.area === Area.Incentives),
		footerPages: (state) => state.pages.filter((page) => page.area === Area.Footer),
		profilePage: (state) => state.pages.find((page) => page.route === RouteNames.Profile),
		newsPage: (state) => state.pages.find((page) => page.route === RouteNames.News),
		pageByGuid: (state) => (guid: string) => state.pages.filter((page) => page.guid === guid)[0],
		pageByType: (state) => (type: PageType) => state.pages.filter((page) => page.pageType === type),
	},
});

// Helper functions
const syncPageResponse = (
	state: { pages: PageConsumer[] },
	pagesResponse: PageConsumer[],
	isSavingUpEnabled: boolean,
) => {
	state.pages =
		state.pages.length === 0
			? pagesResponse
			: pagesResponse.map((page) => {
					const existingPage = state.pages.find((p) => p.guid === page.guid);
					return existingPage || page;
				});

	const profilePage = initProfilePage(state);
	initPageRoutes(state, profilePage, isSavingUpEnabled);
};

const initProfilePage = (state: { pages: PageConsumer[] }): PageConsumer => {
	const profilePage: PageConsumer = {
		id: -1,
		guid: validationConstants.emptyGuid,
		name: '(LabelNavigationProfile)',
		intro: '',
		icon: 'profile',
		// @ts-expect-error: Since profile page doesn't come from the backend. It doesn't have a pagetype.
		pageType: 0,
		area: Area.Undefined,
		openInNewTab: false,
		customUrl: '',
		editUrl: '',
		isAboutThisProjectPage: false,
		link: '/profile',
		target: '',
		image: '',
		route: RouteNames.Profile,
		routeParams: {},
		isAdded: false,
	};
	state.pages.push(profilePage);

	return profilePage;
};

const initPageRoutes = (
	state: { pages: PageConsumer[] },
	profilePage: PageConsumer,
	isSavingUpEnabled: boolean,
): void => {
	const pagesByType = groupBy(state.pages, (page: PageConsumer) => page.area);
	const mainPages = pagesByType[Area.MainPages] || [];
	const helpCenterPages = pagesByType[Area.HelpCenter] || [];
	const footerPages = pagesByType[Area.Footer] || [];
	const incentivePages = pagesByType[Area.Incentives] || [];

	initMainPageRoutes(mainPages);
	initHelpCenterPageRoutes(helpCenterPages);
	initIncentivesPagesRoutes(incentivePages, isSavingUpEnabled);
	initFooterPagesRoutes(footerPages);
	updateLinkForPage(profilePage);
	updateTargetForPage(profilePage);
};

const initMainPageRoutes = (mainPages: PageConsumer[]): void => {
	mainPages.forEach((page) => {
		switch (page.pageType) {
			case PageType.Home: {
				page.route = RouteNames.Home;
				break;
			}
			case PageType.Activities: {
				page.route = RouteNames.Challenges;
				page.routeParams = { pageGuid: page.guid };
				break;
			}
			case PageType.Forum: {
				page.route = RouteNames.Forum;
				page.routeParams = { pageGuid: page.guid };
				break;
			}
			case PageType.News: {
				page.route = RouteNames.News;
				page.routeParams = { pageGuid: page.guid };
				break;
			}
			case PageType.MainPageCustom: {
				page.route = RouteNames.MainpageCustom;
				page.routeParams = { pageGuid: page.guid };
				break;
			}
			default:
				page.route = page.route || RouteNames.Main;
		}

		updateLinkForPage(page, true);
		updateTargetForPage(page);
	});
};

const initHelpCenterPageRoutes = (helpcenterPages: PageConsumer[]): void => {
	helpcenterPages.forEach((page) => {
		switch (page.pageType) {
			case PageType.FAQ:
				page.route = RouteNames.HelpcenterFaq;
				page.routeParams = { helpcenterPage: page.guid };
				break;
			case PageType.PlatformTour:
				page.route = RouteNames.HelpcenterPlatformTour;
				page.routeParams = { helpcenterPage: page.guid };
				break;
			case PageType.Contact:
				page.route = RouteNames.HelpcenterContact;
				page.routeParams = { helpcenterPage: page.guid };
				break;
			case PageType.HelpCenterCustom:
				page.route = RouteNames.HelpcenterCustom;
				page.routeParams = { pageGuid: page.guid };
				break;
			default:
				page.route = RouteNames.Home;
		}
		updateLinkForPage(page, true);
	});
};

const initIncentivesPagesRoutes = async (incentivePages: PageConsumer[], isSavingUpEnabled: boolean): Promise<void> => {
	incentivePages.forEach((page) => {
		switch (page.pageType) {
			case PageType.IncentivesCustom: {
				page.route = RouteNames.IncentivesCustom;
				page.routeParams = { pageGuid: page.guid };
				break;
			}
			case PageType.MyIncentive:
				page.route = isSavingUpEnabled ? RouteNames.IncentivesMySavingUpRewards : RouteNames.IncentivesMyIncentives;
				break;
		}

		updateLinkForPage(page, true);
		updateTargetForPage(page);
	});
};

const initFooterPagesRoutes = (footerPages: PageConsumer[]): void => {
	footerPages
		.filter((page) => {
			if (page.pageType === PageType.MyCookieSettings) {
				page.link = '';
				return false;
			}
			return true;
		})
		.forEach((page: PageConsumer) => {
			page.route = RouteNames.Footer;
			page.routeParams = { pageGuid: page.guid };

			updateLinkForPage(page);
			updateTargetForPage(page);
		});
};

const resolveRoute = (page: PageConsumer): string =>
	router.resolve({
		name: page.route,
		params: page.routeParams,
	})?.href;

const updateLinkForPage = (page: PageConsumer, ignoreCustomUrl?: boolean): void => {
	if (page.pageType === PageType.MyCookieSettings) {
		page.link = '';
	} else if (ignoreCustomUrl) {
		page.link = resolveRoute(page);
	} else {
		page.link = page.customUrl || resolveRoute(page);
	}
};

const updateTargetForPage = (page: PageConsumer): void => {
	page.target = page.customUrl ? (page.openInNewTab ? '_blank' : '_self') : undefined;
};
