import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';
import { validate as isUuid } from 'uuid';
import { hideMigrationDialog } from '../global-guards/migration-guard';

const AdActivationPage = () => import('@/pages/activation-page.vue');

export const adActivationRoute: RouteRecordRaw = {
	name: RouteNames.ADActivation,
	path: '/activation',
	component: AdActivationPage,
	meta: {
		title: '',
		requireLogin: false,
	},
	beforeEnter: (to: RouteLocationNormalized) => {
		// if we do not have the AD login enabled for this square, go to root
		if (!useSquareStore().info.adLogin || !to.query.identity || !isUuid(to.query.identity.toString())) {
			return {
				name: RouteNames.Home,
			};
		}
		hideMigrationDialog();
		return true;
	},
};
