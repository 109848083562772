import { Platform } from 'quasar';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';

const isMobileDevice = () => Platform.is.mobile || Platform.is.ios || Platform.is.android;
let isAlreadyCheckedInTheCurrentUserSession = false;

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	if (isAlreadyCheckedInTheCurrentUserSession) {
		// Already checked if we are on a mobile device
		return;
	}

	if (!isMobileDevice()) {
		// If we are not routing on a mobile device or in the app we don't have to publish events to the app
		isAlreadyCheckedInTheCurrentUserSession = true;
		return;
	}

	if (to.meta.flutterInAppHandler) {
		try {
			window.flutter_inappwebview.callHandler(to.meta.flutterInAppHandler, to.params);
		} catch {
			// do nothing routing can continue
		}
		return;
	}
};
