<template>
	<div class="q-pa-sm isc-selection-radio">
		<q-option-group
			:model-value="selectionOperation"
			:options="filterOptions"
			color="secondary"
			inline
			dense
			class="text-subtitle1 text-weight-thin"
			@update:model-value="emit('selection-operation-change', $event)"
		/>
	</div>
</template>

<script lang="ts" setup>
import { FilterSelectionOperation } from '../isc-filter.types';

export interface Props {
	selectionOperation: FilterSelectionOperation;
}

defineProps<Props>();

const filterOptions = [
	{
		label: 'Any',
		value: FilterSelectionOperation.Any,
	},
	{
		label: 'All',
		value: FilterSelectionOperation.All,
	},
];

const emit = defineEmits<(e: 'selection-operation-change', payload: FilterSelectionOperation) => void>();
</script>
