import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
const UnsubscribePage = () => import('@/pages/unsubscribe-page.vue');

export const unsubscribeRoute: RouteRecordRaw = {
	name: RouteNames.Unsubscribe,
	path: '/unsubscribe/:sqPartId',
	component: UnsubscribePage,
	meta: {
		requireLogin: false,
		title: '(LabelWindowTitleUnsubscribe)',
	},
};
