/**
 * Uses the labels sent to the package via the options in the entry.js file.
 * Labels should be a Record<string, string> with item1 being the label name and item2 being the actual label.
 */
import { has } from 'lodash-es';

let internalLabelFunction: (() => Record<string, string>) | null;
const likeCount = [0, 1, 2];

function getLabels() {
	return internalLabelFunction?.() || {};
}

const getLikesCount = (): number => {
	if (likeCount.length === 0) {
		likeCount.push(0, 1, 2);
	}
	return likeCount.pop() ?? 0;
};

/**
 * @param labelFunction Function which will return the labels.
 */

const setLabelFunction = (labelFunction: (() => Record<string, string>) | null) => {
	internalLabelFunction = labelFunction;
};

/**
 * @param labelName Name of the label to fetch.
 * @param fallback Value returned if label not found.
 * @param replacements Item1: text to be replaced. Item2: text to replace it with.
 * @returns Label with name LabelName. If it exists, otherwise it returns fallback.
 */

const getLabelValue = (labelName: string, fallback: string, replacements?: Record<string, string>) => {
	let label = fallback;
	if (has(getLabels(), labelName)) {
		label = getLabels()[labelName];
	}

	if (replacements === undefined) {
		return label;
	}

	// there are 8 label that use ternary operators having the condition replacement == 1|'true'
	const ternaryRegex =
		"\\{\\s*(?<variable>[^\\s]+)\\s*==\\s*'?(?<value>1|true)'?\\s*\\?\\s*'(?<trueVal>.+)'\\s*" +
		":\\s*'(?<falseVal>.+)'\\s*\\}";
	label = label.replace(new RegExp(ternaryRegex), (_substring, variable, value, trueVal, falseVal) => {
		if (replacements[variable] !== undefined && String(value) === replacements[variable]) {
			return trueVal;
		}

		return falseVal;
	});

	Object.keys(replacements).forEach((key) => {
		label = label.replaceAll(`{${key}}`, replacements[key]);
	});
	return label;
};

/**
 * @param labelName Name of the label to fetch.
 * @param url Url to put in {link|url} replacement.
 * @param fallback Value returned if label not found.
 * @returns Label with name LabelName and with {link|url} replaced by a hyperlink with text 'link' and link url parameter. Returns fallback if label not found.
 */

const getLabelValueReplaceUrl = (labelName: string, url: string, fallback: string) => {
	if (!has(getLabels(), labelName)) {
		return fallback;
	}

	const matchTextUrlPlaceholder = /\{(.+)\|(url)\}/g;
	const labelValue = getLabels()[labelName];
	return labelValue.replace(matchTextUrlPlaceholder, (_match, text) => `<a href="${url}">${text}</a>`);
};

export { setLabelFunction, getLabelValue, getLabelValueReplaceUrl, getLikesCount };
