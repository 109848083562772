import { memoize } from 'lodash-es';
import {
	isFeatureEnabledForSquare as isFeatureEnabledForSquareApi,
	checkFeatureAccessibility as checkFeatureAccessibilityApi,
	isMasterFeatureEnabledForSquare as isMasterFeatureEnabledForSquareApi,
	isForceMobileAppEnabledForSquare as isForceMobileAppEnabledForSquareApi,
} from '@api/services/query/default/FeatureService';

const featureCache = new Map();

export const isFeatureEnabledForSquare = async (
	feature: number,
	invalidateCache: boolean = false,
): Promise<boolean> => {
	if (invalidateCache) {
		featureCache.delete(feature);
	} else {
		if (featureCache.has(feature)) {
			return featureCache.get(feature);
		}
	}

	try {
		const result = await isFeatureEnabledForSquareApi(feature);
		featureCache.set(feature, result);
		return result;
	} catch {
		// If an error occurs, we grant no access to the feature
		return false;
	}
};

export const checkFeatureAccessibility = memoize(async (feature: number): Promise<boolean> => {
	try {
		return await checkFeatureAccessibilityApi(feature);
	} catch {
		// If an error occurs, we grant no access to the feature
		return false;
	}
});

export const isMasterFeatureEnabledForSquare = memoize(async (feature: number): Promise<boolean> => {
	try {
		return await isMasterFeatureEnabledForSquareApi(feature);
	} catch {
		// If an error occurs, we grant no access to the feature
		return false;
	}
});

export const isForceMobileAppEnabledForSquare = memoize(async (): Promise<boolean> => {
	try {
		return await isForceMobileAppEnabledForSquareApi();
	} catch {
		// If an error occurs, we don't force the mobile app
		return false;
	}
});
