import { mergeAttributes, Node } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion from '@tiptap/suggestion';
import { MentionOptions } from '@tiptap/extension-mention';

// Most of this code is copied from the mention extension
// See https://github.com/ueberdosis/tiptap/blob/main/packages/extension-mention/src/mention.ts

type HashtagOptions = MentionOptions;

export const HashtagPluginKey = new PluginKey('hashtag');

export const Hashtag = Node.create<HashtagOptions>({
	name: 'hashtag',

	addOptions() {
		return {
			HTMLAttributes: {},
			renderText({ options, node }) {
				return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
			},
			renderHTML({ options, node }) {
				return ['span', this.HTMLAttributes, `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`];
			},
			suggestion: {
				char: '#',
				pluginKey: HashtagPluginKey,
				command: ({ editor, range, props }) => {
					// increase range.to by one when the next node is of type "text"
					// and starts with a space character
					const nodeAfter = editor.view.state.selection.$to.nodeAfter;
					const overrideSpace = nodeAfter?.text?.startsWith(' ');

					if (overrideSpace) {
						range.to += 1;
					}

					editor
						.chain()
						.focus()
						.insertContentAt(range, [
							{
								type: this.name,
								attrs: props,
							},
							{
								type: 'text',
								text: ' ',
							},
						])
						.run();

					window.getSelection()?.collapseToEnd();
				},
				allow: ({ state, range }) => {
					const $from = state.doc.resolve(range.from);
					const type = state.schema.nodes[this.name];
					const allow = !!$from.parent.type.contentMatch.matchType(type);

					return allow;
				},
			},
			deleteTriggerWithBackspace: false,
		};
	},

	group: 'inline',

	inline: true,

	selectable: false,

	atom: true,

	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute('data-id'),
				renderHTML: (attributes) => {
					if (!attributes.id) {
						return {};
					}

					return {
						'data-id': attributes.id,
					};
				},
			},

			label: {
				default: null,
				parseHTML: (element) => element.getAttribute('data-label'),
				renderHTML: (attributes) => {
					if (!attributes.label) {
						return {};
					}

					return {
						'data-label': attributes.label,
					};
				},
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: `span[data-type="${this.name}"]`,
			},
		];
	},

	// eslint-disable-next-line @typescript-eslint/naming-convention
	renderHTML({ node, HTMLAttributes }) {
		const html = this.options.renderHTML({
			options: this.options,
			node,
		});

		if (typeof html === 'string') {
			return ['span', mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes), html];
		}
		return html;
	},

	renderText({ node }) {
		return this.options.renderText({
			options: this.options,
			node,
		});
	},

	addKeyboardShortcuts() {
		return {
			Backspace: () =>
				this.editor.commands.command(({ tr, state }) => {
					let isHashtag = false;
					const { selection } = state;
					const { empty, anchor } = selection;

					if (!empty) {
						return false;
					}

					state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
						if (node.type.name === this.name) {
							isHashtag = true;
							tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

							return false;
						}
					});

					return isHashtag;
				}),
		};
	},

	addProseMirrorPlugins() {
		return [
			Suggestion({
				editor: this.editor,
				...this.options.suggestion,
			}),
		];
	},
});
