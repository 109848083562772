<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { getLabelValue } from '@ui/services/label-service';
import { computed, watch } from 'vue';

const emit = defineEmits<(eventname: 'update:modelValue', value: string) => void>();

const props = withDefaults(
	defineProps<{
		modelValue: string;
		errorMessage?: string;
		readOnly?: boolean;
		autocomplete?: boolean;
		disable?: boolean;
		emailLabel?: string;
		emailSendMessage?: string;
		tooltipMessage?: string;
	}>(),
	{
		modelValue: '',
		errorMessage: '',
		readOnly: false,
		autocomplete: true,
		disable: false,
		emailLabel: '',
		emailSendMessage: '',
		tooltipMessage: '',
	},
);

const emailAddress = computed({
	get: () => props.modelValue,
	set: async (value) => {
		emit('update:modelValue', value);
	},
});

const v$ = useVuelidate(
	{
		emailAddress: {
			required: helpers.withMessage(getLabelValue('LabelEmailRequired', 'An e-mail address is required'), required),
			email: helpers.withMessage(
				getLabelValue('LabelEmailFormatNotCorrect', 'The e-mail address format is incorrect'),
				email,
			),
			propErrorMessage: helpers.withMessage(
				() => props.errorMessage || '',
				() => !props.errorMessage,
			),
		},
	},
	{ emailAddress },
);

watch(
	() => props.errorMessage,
	(value: string | undefined) => {
		// when errormessage changes to true, make the field dirty
		if (value) {
			v$.value.emailAddress.$touch();
		}
	},
	{ immediate: true },
);
</script>

<template>
	<div class="isc-email">
		<q-input
			v-model="emailAddress"
			type="email"
			name="email"
			input-class="isc-email__input text-body2"
			label-slot
			bottom-slots
			no-error-icon
			:autocomplete="autocomplete"
			:readonly="readOnly"
			:error="v$.emailAddress.$errors.length > 0"
			:disable="disable"
			@blur="v$.emailAddress.$touch"
		>
			<template #label>
				<div class="isc-email__label all-pointer-events text-body2">
					<div class="isc-email__label-wrapper">
						<label class="isc-email__label">{{ props.emailLabel }}*</label>
						<i
							v-if="tooltipMessage != ''"
							class="far fa-circle-info isc-email__tooltip-icon"
						>
							<q-tooltip
								class="floating label"
								anchor="top middle"
								self="top middle"
								:offset="[0, 30]"
							>
								{{ props.tooltipMessage }}
							</q-tooltip>
						</i>
					</div>
				</div>
			</template>
			<template #error>
				<span class="isc-email__error text-weight-regular text-caption">
					{{ v$.emailAddress.$errors[0].$message }}
				</span>
			</template>
			<template #hint>
				<span class="isc-email__EmailSend text-color-green text-weight-regular text-caption">
					{{ props.emailSendMessage }}
				</span>
			</template>
		</q-input>
	</div>
</template>

<style lang="scss">
.text-color-green {
	color: $green;
}

.isc-email__label-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.isc-email__tooltip-icon {
	margin-left: 8px;
	cursor: pointer;
	z-index: 1000;
}
</style>
