<template>
	<div
		class="user-notification-base cursor-pointer"
		role="button"
		tabindex="0"
		aria-haspopup="true"
		:aria-expanded="modalShown"
		:aria-label="getLabelValue('LabelNotifications', 'Notifications')"
		@click="toggleMenu"
		@keydown.enter="toggleMenu"
	>
		<q-avatar
			icon="fas fa-bell"
			:size="$q.screen.lt.md ? 'xl' : 'lg'"
			padding="sm"
			square
		>
			<isc-badge-wrapper
				v-if="notifications.length"
				:notifications="notifications.length"
			/>
		</q-avatar>
	</div>
	<q-menu
		v-if="notifications.length"
		class="user-notification-base__menu relative-position shadow-1"
		anchor="bottom right"
		self="top right"
		role="menu"
		:v-modal="modalShown"
		:offset="props.offset"
	>
		<q-list class="user-notification-base__list">
			<q-item
				v-for="notification in notifications"
				:key="notification.guid"
				v-close-popup
				class="user-notification-base__wrapper"
				clickable
				role="menuitem"
				@click="emit('notificationClicked', notification.guid)"
			>
				<q-item-section class="user-notification-base__item text-body2 q-py-sm">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<div v-html="notification.label" />
					<!-- eslint-enable vue/no-v-html -->
				</q-item-section>
			</q-item>
		</q-list>
	</q-menu>
</template>

<script setup lang="ts">
import IscBadgeWrapper from '../badge-wrapper/isc-badge-wrapper.vue';
import { UserNotificationResponse } from '@api/models/query';
import { getLabelValue } from '@ui/services/label-service';
import { ref } from 'vue';

export interface Props {
	notifications: UserNotificationResponse[];
	offset?: number[];
}

const props = withDefaults(defineProps<Props>(), {
	offset: () => [0, 20],
});

const emit = defineEmits<(e: 'notificationClicked', notificationGuid: string) => void>();
const modalShown = ref(false);

const toggleMenu = () => {
	modalShown.value = !modalShown.value;
};
</script>
<style lang="scss">
.user-notification-base {
	&__menu {
		overflow-x: hidden;
	}
	&__list {
		width: 300px;
	}
	&__wrapper:not(:last-child) {
		border-bottom: 1px solid $border-color;
	}
}
</style>
