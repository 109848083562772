import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { Role } from '@/common/constants/enums';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	if (!to.params?.pageGuid) {
		return;
	}

	// If no login is required, we don't need to check anything
	if (!to.meta?.requireLogin) {
		return;
	}

	// API for allowed to navigate is for participants only
	const authStore = useAuthenticationStore();
	if (!authStore.isAuthorized || authStore.userRole !== Role.Participant) {
		return;
	}

	const hasAccess = await usePagesStore().allowedToNavigateToPage(to.params.pageGuid as string);
	if (!hasAccess) {
		return {
			name: RouteNames.LandingPage,
		};
	}

	return;
};
