// We need to make sure that our store can be used in our AngularJS controllers
// without having to change the code there
import {
	convertCamelCasedObjectToPascalCasedObject,
	convertPascalCasedObjectToCamelCasedObject,
} from '@ui/common/utils/convert-case';
import { PageConsumer, usePagesStore } from '@/store/pages-store';
import { noop } from 'lodash-es';

interface PageService {
	goToPage: (page: PageConsumer, replace: boolean) => void;
	resetPagesCache: () => void;
	mainPages: PageConsumer[];
	helpCenterPages: PageConsumer[];
	incentives: PageConsumer[];
	footerPages: PageConsumer[];
	profilePage: PageConsumer | null;
	allPages: PageConsumer[];
	PageType: Record<string, number>;
	Area: Record<string, number>;
	init: () => Promise<void>;
}

export default new Proxy(
	{
		goToPage: (page, replace) =>
			usePagesStore().goToPage(convertPascalCasedObjectToCamelCasedObject(page) as PageConsumer, replace),
		resetPagesCache: noop,
		footerPages: [],
		helpCenterPages: [],
		incentives: [],
		mainPages: [],
		profilePage: null,
		allPages: [],
		PageType: {
			home: 1,
			activities: 2,
			forum: 3,
			ideabox: 4,
			news: 5,
			mainPageCustom: 6,
			faq: 7,
			platformTour: 8,
			contact: 9,
			helpCenterCustom: 10,
			footerCustom: 11,
			incentivesCustom: 12,
			myIncentive: 13,
		},
		Area: {
			undefined: 0,
			mainPages: 1,
			helpCenter: 2,
			footer: 3,
			incentives: 4,
			splash: 5,
		},
		init: () => Promise.resolve(),
	},
	{
		get: (target, key: keyof PageService) => {
			if (key !== 'goToPage' && key !== 'PageType' && key !== 'Area' && key !== 'resetPagesCache' && key !== 'init') {
				let val = null;
				switch (key) {
					case 'allPages':
						val = usePagesStore().pages;
						break;
					case 'incentives':
						val = usePagesStore().incentivePages;
						break;
					default:
						val = usePagesStore()[key];
						break;
				}
				return convertCamelCasedObjectToPascalCasedObject(val);
			}

			return Reflect.get(target, key);
		},
	},
) as PageService;
