import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';

export const signUpRoute: RouteRecordRaw = {
	name: RouteNames.SignUp,
	path: '/signup',
	component: () => import('@/pages/signup-page.vue'),
	meta: {
		requireLogin: false,
		title: '(LabelAccountSignUp)',
	},
	beforeEnter: () => {
		// if the square is not for open registration then return to loginpage
		// if the square is for open registration but only has 1 country also return to login page
		const squareInfo = useSquareStore().info;
		if (!squareInfo.isOpenRegistration || (squareInfo.countries?.length ?? 0) <= 1) {
			return { name: RouteNames.Login };
		}
		return true;
	},
};
