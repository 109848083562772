import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';
import { validate as isUuid } from 'uuid';
import { useAuthenticationStore } from '@/store/authentication-store';

const AdSignUpPage = () => import('@/pages/ad-signup-page.vue');

export const adSignUpRoute: RouteRecordRaw = {
	name: RouteNames.AdSignUp,
	path: '/signup',
	component: AdSignUpPage,
	meta: {
		title: '',
		requireLogin: false,
	},
	beforeEnter: (to: RouteLocationNormalized) => {
		// if we do not have the AD login enabled for this square, go to root
		if (!useSquareStore().info.adLogin || !to.query.identity || !isUuid(to.query.identity.toString())) {
			return {
				name: RouteNames.Home,
			};
		}
		// if authenticated, go to root
		if (useAuthenticationStore().isAuthorized) {
			return {
				name: RouteNames.Home,
			};
		}
		return true;
	},
};
