/* eslint-disable @typescript-eslint/no-explicit-any */
import * as tracking from '@/common/utils/tracking';

export const trackingService = {
	researchActivityCardOnHomePageClick: (card: any) =>
		tracking.trackResearchActivityCardOnHomePageClick(card.CommunicationSampleGuid),
	researchActivityCardOnChallengesPageClick: (card: any) =>
		tracking.trackResearchActivityCardOnChallengesPageClick(card.CommunicationSampleGuid),
	platformNotificationClick: (sampleGuid: string, channelType: number) =>
		tracking.trackPlatformNotificationClick(sampleGuid, channelType),
	researchActivityNotificationClick: (sampleGuid: string, channelType: number) =>
		tracking.trackResearchActivityNotificationClick(sampleGuid, channelType),
	researchActivityNotificationView: (sampleGuid: string) => tracking.trackResearchActivityNotificationView(sampleGuid),
	researchActivityCardOnHomePageView: (card: any) =>
		tracking.trackResearchActivityCardOnHomePageView(card.CommunicationSampleGuid),
	researchActivityCardOnChallengesPageView: (card: any) =>
		tracking.trackResearchActivityCardOnChallengesPageView(card.CommunicationSampleGuid),
	engagementCardOnHomePageClick: (targetGuid: string) => tracking.trackEngagementCardOnHomePageClick(targetGuid),
	engagementCardOnHomePageView: (targetGuid: string) => tracking.trackEngagementCardOnHomePageView(targetGuid),
	newsCardOnHomePageClick: (targetGuid: string) => tracking.trackNewsCardOnHomePageClick(targetGuid),
	newsCardOnHomePageView: (targetGuid: string) => tracking.trackNewsCardOnHomePageView(targetGuid),
	newsCardOnNewsPageClick: (targetGuid: string) => tracking.trackNewsCardOnNewsPageClick(targetGuid),
	newsCardOnNewsPageView: (targetGuid: string) => tracking.trackNewsCardOnNewsPageView(targetGuid),
};
