<script setup lang="ts">
import { computed } from 'vue';
import { emptyImage, useImagesStore } from '@/store/images-store';

const props = withDefaults(defineProps<{ showLogoTopBar?: boolean; showLogoCentered?: boolean }>(), {
	showLogoTopBar: true,
	showLogoCentered: false,
});

const imagesStore = useImagesStore();
const images = computed(() => imagesStore.$state);
const logoDirection = computed(() => (props.showLogoCentered === true ? 'center' : 'left'));
</script>

<template>
	<div
		v-show="images.logoTopBar && images.logoTopBar !== emptyImage && showLogoTopBar"
		class="header-consumer-logo flex"
	>
		<q-img
			:src="images.logoTopBar"
			class="header-consumer-logo__img"
			alt=""
			:position="logoDirection"
			fit="contain"
			height="35px"
		/>
	</div>
</template>
