import { useSquareStore } from '@/store/square-store';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { RegistrationStatus } from '@api/models/query';
import { squareParticipantDetails } from '@api/services/query/default/ParticipantService';
import { prepareParticipantForActivation } from '@api/services/command/default/ParticipantService';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteLocationNormalized } from 'vue-router';

export const registrationRoute: RouteRecordRaw = {
	name: RouteNames.Registration,
	path: '/registration/:sqPartId',
	component: () => import('@/pages/registration-page.vue'),
	meta: {
		requireLogin: false,
		title: '(LabelWindowTitleRegistration)',
	},
	beforeEnter: async (to: RouteLocationNormalized) => {
		const squareParticipantGuid = to.params.sqPartId as string;

		const squareStore = useSquareStore();
		if (squareStore.info.adLogin) {
			// if this is an AD login enabled square, we go to the AD activation page
			return {
				name: RouteNames.ADActivation,
				query: {
					identity: squareParticipantGuid,
				},
			};
		}

		const handleRoutingBySquareParticipantStatus = async () => {
			try {
				const response = await squareParticipantDetails(squareParticipantGuid);
				if (response.detail) {
					if (response.detail.suspended) {
						return { name: RouteNames.Login };
					}
					switch (response.detail.status) {
						case RegistrationStatus.SingleOptIn:
						case RegistrationStatus.SingleOptInReminder: {
							await prepareParticipantForActivation({ squareParticipantGuid });
							return;
						}
						case RegistrationStatus.Active: {
							return { name: RouteNames.Home };
						}
						case RegistrationStatus.Unsubscribed: {
							return {
								name: RouteNames.Reactivate,
								params: { subscribeGuid: squareParticipantGuid },
							};
						}
					}
				}
			} catch {
				return { name: RouteNames.Login };
			}
		};

		// Compares the logged in SquareParticipantGuid with the Guid from the url
		// Performs a logout when they are different
		const validateCurrentUser = async () => {
			const authenticationStore = useAuthenticationStore();
			const loggedInUserGuid = authenticationStore.tokenInfo?.squareParticipantGuid;
			if (loggedInUserGuid && loggedInUserGuid.toLowerCase() !== squareParticipantGuid.toLowerCase()) {
				await authenticationStore.signout(false, false);
				return false;
			}
			return true;
		};

		if (await validateCurrentUser()) {
			return await handleRoutingBySquareParticipantStatus();
		}

		return true;
	},
};
