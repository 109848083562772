import { getReplyForumRoute } from '@/common/utils/conversation';
import {
	hasNullSsoToken,
	hasSsoToken,
	showLimitedAccessDialog,
	ssoLogin,
	getSsoTokenFromUrl,
} from '@/common/utils/sso';
import { ItoConversationParamFromURI, onBehalfOfGuidFromURI, toConversationParamFromURI } from '@/common/utils/url';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from './route-names.enum';
import { Role } from '@api/models/query';

const getRedirectRoute = async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	const squareStore = useSquareStore();

	const communicationParams = toConversationParamFromURI(to);
	if (!hasSsoToken(to) && !squareStore.info.adLogin) {
		if (communicationParams.conversationGuid) {
			return getReplyForumRoute(communicationParams.conversationGuid, communicationParams.redirectType);
		}

		return { name: RouteNames.Login };
	}

	if (!hasSsoToken(to) || hasNullSsoToken(to) || to?.query.token === '') {
		if (to.query.isVerified !== '1') {
			return {
				name: squareStore.info.adLogin ? RouteNames.AdSignIn : RouteNames.LoginError,
			};
		}
	}

	return await getRedirectRouteInternally(
		to,
		squareStore.info.ssoEnabled,
		squareStore.info.adLogin,
		squareStore.info.adMigration,
		communicationParams,
	);
};

const getRedirectRouteInternally = async (
	to: RouteLocationNormalized,
	ssoEnabled: boolean,
	adLogin: boolean,
	adMigration: boolean,
	communicationParams: ItoConversationParamFromURI,
) => {
	const token = ssoEnabled || adLogin ? await ssoLogin(to, adLogin, adMigration) : getSsoTokenFromUrl(to);

	// ssoLogin might return a route location instead of a token
	// if it does, we will return the route
	const route = token as RouteLocationNormalized;
	if (!route && !token) {
		return false;
	}

	if (route && route.name) {
		return route;
	}

	const authStore = useAuthenticationStore();
	await authStore.setToken(token as string);

	await authStore.setImpersonate(to.query.on_behalf_of?.toString());

	if (communicationParams.conversationGuid) {
		return getReplyForumRoute(communicationParams.conversationGuid, communicationParams.redirectType);
	}

	// we proceed to eventually use redirect to MM only if not Human8 role
	if (authStore.userRole !== Role.Human8) {
		if (adLogin === true && to.query?.from_admin !== '1' && to.query?.from_observer !== '1') {
			// redirect to MM if needed, based on square participant role
			await authStore.postLoginAction(false);
			if (to.query.from) {
				return { path: decodeURIComponent(to.query.from.toString()) };
			}
		}
	}

	// if there is a `from` query, return that path
	if (to.query.from) {
		return { path: decodeURIComponent(to.query.from.toString()) };
	}
	return { name: RouteNames.Home };
};

export const ssoRoute: RouteRecordRaw = {
	name: RouteNames.SSO,
	path: '/sso',
	component: {},
	meta: {
		title: '',
		requireLogin: false,
	},
	beforeEnter: async (to) => {
		try {
			if (to.query.invalid) {
				const invalidRoute: NavigationGuardReturn = {
					name: RouteNames.SignInError,
				};
				if (to.query.noaccount) {
					invalidRoute.name = RouteNames.AdSignIn;
					invalidRoute.query = { noaccount: 1 };
				}
				return invalidRoute;
			}
			const redirectRoute = await getRedirectRoute(to);
			const squareParticipantGuid = onBehalfOfGuidFromURI(to);

			if (squareParticipantGuid && typeof redirectRoute === 'object') {
				redirectRoute.query = {
					...redirectRoute.query,
					onBehalfOf: squareParticipantGuid,
				};
			}

			return redirectRoute;
		} catch {
			const signout = async (invalidToken: boolean) => {
				const authStore = useAuthenticationStore();
				await authStore.signout(false, invalidToken);
			};
			if (hasNullSsoToken(to)) {
				try {
					showLimitedAccessDialog(true, () => signout(false));
				} catch {
					await signout(false);
				}
			} else {
				const hasToken = hasSsoToken(to);
				await signout(hasToken);
				if (hasToken) {
					return false;
				}
			}

			return {
				name: useSquareStore().info.adLogin ? RouteNames.Home : RouteNames.Login,
			};
		}
	},
};
