import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';

const MorePage = () => import('@/pages/more-page.vue');

export const moreRoute: RouteRecordRaw = {
	name: RouteNames.More,
	path: '/more',
	component: MorePage,
	meta: {
		requireLogin: true,
		title: '(LabelWindowTitleHome)',
	},
};
