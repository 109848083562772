// We need to make sure that our Vuex store can be used in our AngularJS controllers
// without having to change the code there
import type { memoize } from 'lodash-es';
import { isSsoDomain, showLimitedAccessDialog, ssoLogin, startAdminSsoLogin } from '@/common/utils/sso';

interface SsoService {
	startAdminSsoLogin: () => void;
	isSsoDomain: (mail: string) => Promise<boolean>;
	ssoLogin: (() => Promise<string | void>) & ReturnType<typeof memoize>;
	showLimitedAccessDialog: (isSquare?: boolean) => Promise<unknown>;
}

export default {
	isSsoDomain,
	showLimitedAccessDialog,
	ssoLogin,
	startAdminSsoLogin,
} as SsoService;
