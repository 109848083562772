import { Settings } from 'luxon';
import { Quasar } from 'quasar';
import { RouteLocationNormalized } from 'vue-router';

import { FATHOM_SITE_ID, FATHOM_URL, COOKIEYES_SITE_ID } from '@/common/env.config';
import { imagesConstants } from '@/common/constants/constants';
import { ClientVideoStorage } from '@/common/constants/enums';
import { getCurrentSubdomain } from '@/common/utils/url';
import router from '@/router';
import { squareInfo as getSquareInfo } from '@api/services/query/default/ProjectService';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useConstantsStore } from '@/store/constants-store';
import { useImagesStore } from '@/store/images-store';
import { usePagesStore } from '@/store/pages-store';
import { useParticipantStore } from '@/store/participant-store';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useNotificationsStore } from '@/store/notifications-store';
import { ref } from 'vue';
import { getSquareLandingPageConfig, selectSquareDesign } from '@api/services/query/default/SquareService';

export { setupAppInsights } from '@/common/app-insights';
export { initPlatformHealth } from '@/common/utils/platform-health';

/**
 * Will be set to true once all startup functions have been executed.
 */
export const isInitialized = ref(false);

export const initStores = async (route: RouteLocationNormalized) => {
	useConstantsStore().init();
	useParticipantStore().init();
	const authStore = useAuthenticationStore();
	await authStore.init();
	await usePagesStore().init();
	await useNotificationsStore().init(route);
};

export const setSquareInfo = async () => {
	const squareStore = useSquareStore();
	if (squareStore.info.id === -1) {
		try {
			const squareInfo = await getSquareInfo();

			if (!squareInfo.detail) {
				router.push({
					name: RouteNames.NotFound,
					query: { noSquareInfo: 'true' },
				});
				return false;
			}

			// If we get a different subdomain from the getSquareInfo call than the current one,
			// It means that the current subdomain is an old subdomain so the user should be redirected to the new one instead.
			const currentSubdomain = getCurrentSubdomain();
			if (currentSubdomain && squareInfo.detail?.subdomain?.toLowerCase() !== currentSubdomain) {
				location.replace(location.href.replace(currentSubdomain, squareInfo.detail?.subdomain || ''));
			}

			squareStore.setSquareInfo({
				...squareInfo.detail,
				videoStorage: squareInfo.videoStorage ?? ClientVideoStorage.Mux,
			});
		} catch {
			router.push({
				name: RouteNames.NotFound,
				query: { noSquareInfo: 'true' },
			});
			return false;
		}
	}
	return true;
};

export const setCustomStyles = async () => {
	const squareStore = useSquareStore();
	const design = await selectSquareDesign(squareStore.info.guid);
	squareStore.setSquareDesign(design);

	if (design.squareDesign) {
		document.body.style.setProperty('--background-color', design.squareDesign.backgroundColor || '');
		document.body.style.setProperty('--navigation-bar-start', design.squareDesign.navigationBarLeft || '');
		document.body.style.setProperty('--navigation-bar-end', design.squareDesign.navigationBarRight || '');
		document.body.style.setProperty('--navigation-bar-text', design.squareDesign.navigationBarText || '');
		document.body.style.setProperty('--q-primary', design.squareDesign.baseColor1 || '');
		document.body.style.setProperty('--square-primary', design.squareDesign.baseColor1 || '');
	}

	// append custom css
	if (design.customCss) {
		const style = document.createElement('style');
		style.innerText = design.customCss;
		document.head.appendChild(style);
	}

	// apply images
	const images: string[] = [];
	if (design.squareDesign?.hasLogoTopBar) {
		images.push(imagesConstants.logoTopBar);
	}
	if (design.squareDesign?.hasHeaderLogo) {
		images.push(imagesConstants.headerLogo);
	}
	if (design.squareDesign?.hasHeaderDesktop) {
		images.push(imagesConstants.headerDesktop);
	}
	if (design.squareDesign?.hasHeaderMobile) {
		images.push(imagesConstants.headerMobile);
	}
	if (design.squareDesign?.hasBackground) {
		images.push(imagesConstants.background);
	}

	// if AD login is enabled, check and apply landing page images
	if (squareStore.info.adLogin) {
		const landingConfig = await getSquareLandingPageConfig();
		if (landingConfig?.hasTopLogo) {
			images.push(imagesConstants.adLandingPageLogoTopBar);
		}
		if (landingConfig?.hasBackground) {
			images.push(imagesConstants.adLandingPageBackground);
		}
	}
	useImagesStore().applyImages(images);
};

export const setLanguage = async () => {
	const constanstStore = useConstantsStore();
	const squareStore = useSquareStore();
	const squareDetails = squareStore.info;
	if (squareDetails.language !== constanstStore.language || !constanstStore.isLabelsSet) {
		constanstStore.setLanguage(squareDetails.language || 'en');
		await constanstStore.fetchLabels();
	}
	Settings.defaultLocale = squareDetails.language || 'en';

	// Mapping based on available languages in Quasar (https://github.com/quasarframework/quasar/tree/dev/ui/lang)
	const quasarLanguageMap: Record<string, string> = {
		ar: 'ar',
		az: 'ar-Latn',
		bg: 'bg',
		bn: 'bn',
		ca: 'ca',
		cs: 'cs',
		da: 'da',
		de: 'de',
		el: 'el',
		en: 'en-US',
		eo: 'eo',
		es: 'es',
		et: 'et',
		fa: 'fa',
		fi: 'fi',
		fr: 'fr',
		gn: 'gn',
		he: 'he',
		hr: 'hr',
		hu: 'hu',
		id: 'id',
		is: 'is',
		it: 'it',
		ja: 'ja',
		km: 'km',
		ko: 'ko-KR',
		kur: 'kur-CKB',
		lt: 'lt',
		lu: 'lu',
		lv: 'lv',
		ml: 'ml',
		ms: 'ms',
		my: 'my',
		nb: 'nb-NO',
		nl: 'nl',
		pl: 'pl',
		pt: 'pt',
		ro: 'ro',
		ru: 'ru',
		sk: 'sk',
		sl: 'sl',
		sr: 'sr',
		sv: 'sv',
		ta: 'ta',
		th: 'th',
		tr: 'tr',
		ug: 'ug',
		uk: 'uk',
		vi: 'vi',
		zh: 'zh-CN',
	};
	const extension = 'js';
	const language = `${quasarLanguageMap[squareDetails.language || 'en-US'] || 'en-US'}.${extension}`;

	const pack = await import(`../../../../node_modules/quasar/lang/${language}`);
	Quasar.lang.set(pack.default);
};

export const addFathom = () => {
	// Append Fathom script
	if (FATHOM_SITE_ID) {
		const scriptTag = document.createElement('script');
		scriptTag.type = 'text/javascript';
		scriptTag.src = FATHOM_URL;
		scriptTag.defer = true;
		const dataSite = document.createAttribute('data-site');
		scriptTag.setAttributeNode(dataSite);
		dataSite.value = FATHOM_SITE_ID;
		const dataSpa = document.createAttribute('data-spa');
		dataSpa.value = 'auto';
		scriptTag.setAttributeNode(dataSpa);
		document.body.appendChild(scriptTag);
	}
};

export const addCookieYes = () => {
	// Add CookieYes script
	if (COOKIEYES_SITE_ID) {
		const scriptTag = document.createElement('script');
		scriptTag.id = 'cookieyes';
		scriptTag.defer = true;
		scriptTag.type = 'text/javascript';
		scriptTag.src = `https://cdn-cookieyes.com/client_data/${COOKIEYES_SITE_ID}/script.js`;
		document.body.appendChild(scriptTag);
	}
};
